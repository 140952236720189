
.stampCollectionBox{
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .buttonGroupContainer {
    overflow-y: auto;
    padding: 10px;
    height: 100%;
    &::-webkit-scrollbar{
			width: 5px;
			background-color: #725f5a;
		}
		&::-webkit-scrollbar-thumb {
			box-shadow: inset 0 0 6px rgba(0,0,0,.3);
			background-color: #cf9482;
		}

    .buttonGroup{
      padding: 10px 0 20px;
    
      .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        border-bottom: 0.5px solid #A1A4B1;
        padding-bottom: 10px;
    
        .groupName{
          padding-left: 6px;
        }
      }
    
      .stamps{
        display: flex;
        flex-wrap: wrap;
        .stamp{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 33%;
          padding: 19px 0;
        }
    
      }
    }
  }

  .closeButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;

    .closeButton{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 40px;
      padding: 10px 20px;
      font-size: 14px;
      border-radius: 25px;
      color: #fff;
      background-color: #EC7963;
      cursor: pointer;
    }
  }
  
}