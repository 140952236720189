@import '../../../style/media';
.paiNoteRecords {
  height: calc(100% - 67px - 10px);
  
  .text {
    font-size: 1.2em;
    font-weight: 700;
    color: #212B36;
    margin-bottom: .3em;
  }

  .thumbnailWrapper {
    &.horizontal{
      display: flex;
      align-items: flex-start;
      overflow-x: auto;
      gap: .5em;

    }
    &.rightToLeft {
      direction: rtl;
    }

    &.doublePage {
      .thumbnailGroup {
        flex: 0 0 20%;
      }
    }
  }
}

.recordCard {
  border: 2px solid #DFE3E8;
  padding: .7em;
  font-size: .8em;
  background: white;
  border-radius: .5em;
  color: #212B36;
  cursor: pointer;
  flex: 0 0 35vh;
  aspect-ratio: 7.5;

  &.active {
    background: #D9F1F8;
    border: 2px solid #315D83;
  }
  
  .recordHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .recordText {
    span:nth-of-type(1) {
      font-weight: 700;
      font-size: .7em;
      color: #212B36;
      background: #FFB73D;
      padding: .2em .7em;
      border-radius: .5em;
      margin-right: .4em;
      vertical-align: text-top;

      &.done {
        color: #637381;
        background: #DFE3E8;
      }
    }

    span:nth-of-type(3) {
      font-size: .6em;
      display: block;
      line-height: 2.3em;
      color: #637381;
    }
  }

  .thumbnailWrapper {
    width: 100%;
    border-radius: .3em;
    
    img {
      width: 50%;
      height: 100%;
    }
  }
}