footer {
    display: flex;
    justify-content: center;

    .container {
        width: 100%;
    }

    .connectionInfo {
        text-align: center;
        font-size: 14px;
        padding: 20px;
    }

    .command {
        color: #6c757d;
        width: 70%;
        margin: auto;
    }

    .connectionUs {
        font-size: 2rem;
    }

    .connectionInfoHR {
        width: 70%;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .connectionInfoButtons {
        display: flex;
        -ms-flex-wrap: wrap;
        //flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .connectionInfoButtons div {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .connectionInfoButtons div div {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .connectionInfoButtons div i {
        color: #6c757d;
        font-size: 2em;
    }

    .connectionInfoButtons div a {
        text-decoration: none;
        color: #d42d26;
    }

    @media screen and (max-width: 500px) {
        .connectionInfoButtons {
            display: block;
        }

        .connectionInfoButtons div {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .top {
        display: flex;
        justify-content: space-between;

        .list_box {
            .list_sub_box {
                list-style: none;
                padding: 0;

                .list {
                    padding: 5px;
                    margin: 15px 0;
                    cursor: pointer;

                    &:hover {
                        background-color: #ddd;
                    }
                }
            }
        }
    }

    .bottom {
        text-align: center;
        font-size: 14px;
        padding: 20px 0;
        color: #fff;
        background-color: #d42d26;
        display: flex;
        justify-content: center;
        & > div {
            margin-left: 10px;
        }
        & .copyrightBtn {
            cursor: pointer;
        }
    }
}
