.zhuyinImage {
  display: flex;
  flex-flow: row-reverse;
  font-family: cursive;

  & > div {
    margin: 0 1vmin;
  }

  .options {
    margin: .5vmin 1vmin;

    .audio {
    }

    .tabBox {
      text-align: center;

      .tab {
        background-color: #85e7e2;
        border-radius: 5px;
        padding: 1vmin;
        margin: 1vmin 0;
        font-size: 1.75rem;
        cursor: pointer;

        &.active {
          background-color: #eb95b7;
        }
      }
    }
  }

  .title {
    writing-mode: vertical-rl;
    padding: 0 1.75vmin;
    margin-right: 4vmin;
    line-height: 4;
    display: inline-table;

    ruby {
      rt {
        font-family: BopomofoRuby;
        font-size: 2rem;
      }
    }
  }

  .img {
    height: 100%;

    img {
      height: 100%;
    }
  }
}