.volumeBarWrapper{
    display: flex;
    .volumeBar{
        display: flex;
        align-items: center;
        position: relative;
        width: 0;
        transition: .3s;
        overflow: hidden;
        cursor: pointer;
        &:before{
            content:'';
            width: 100%;
            height: 6px;
            background-color: #ccc;
        }
        &.active{
            transition: .3s;
        }
        .volumeUsage{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: #cc0000;
            width: 70%;
            height: 6px;
        }
        .range{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            margin:0;
            opacity: 0;
            cursor: pointer;
        }
    }
}
