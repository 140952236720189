.toolboxContainer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);
  z-index: 100000;
  // background-color: rgba(0,0,0,.5);
  .toolbox{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 55%;
    height: auto;
    min-height: 60%;
    background-color: #51464c;
    box-shadow: 2px 2px 10px #333;
    border-radius: 20px;
    padding: 30px 30px;
    @media screen and (max-width: 1280px) {
      width: 75%;
    }
    @media screen and (max-width: 1024px) {
      width: 90%;
    }
    .closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 12px;
      margin-left: auto;
      box-shadow: 1px 1px 5px #333;
      background-color: #fff;
      font-size: 3vmin;
      color: #ec6464;
      cursor: pointer;
    }

    .toolsList {
      display: flex;
      margin-bottom: auto;
      .btn {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        background: radial-gradient(
            circle at 50% -816%,
            #8bbee6 93%,
            #2b8cd1 50%
        );
        border-radius: 5px;
        cursor: pointer;
        font-size: 2vmin;
        margin: 5px;
    }

      .text {
          font-size: 0.8em;
          margin: 5px 0px;
          font-weight: 700;
          padding: 0px 10px;
          color: #fff;
      }
    }

    .settingControls{
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .resetButton{
        border: 3px solid #ec6464;
        font-size: 2vmin;
        color: #ec6464;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
      }
      .tabContainer{
        display: flex;
        align-items: center;
        .tab{
          padding: 10px;
          color: #fff;
          background-color: #ec6464;
          border: 1px solid #ec6464;
          border-radius: 5px;
          font-size: 2vmin;
          cursor: pointer;
          &.active,
          &:hover{
            background-color: #fff;
            color: #ec6464;
          }
          & + .tab {
            margin-left: 20px;
          }

          @media screen and (max-width: 1280px) {
            font-size: 2vmin;
          }

          @media screen and (max-width: 840px) {
            font-size: 1vmin;
          }
        }
      }
  }
  }
}