.videoPlayer {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);

  .videoError {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    color:#fff;
    width: max-content;
    height: max-content;
    z-index: 99999;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .videoText {
    font-size: 28px;
    line-height: 41px;
    text-align: center;
    margin-bottom: 65px;
  }

  .videoMark {
    margin-bottom: 78px;
  }

  .videoButton {
    width: 156px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EC7963;
    border-radius: 24px;
    color:#fff;
    cursor: pointer;
    border: 0;
  }
  
  .svgContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    direction: ltr;
    svg {
      pointer-events: none;
    }
  }

  &.cursorDrag {
    &,
    canvas {
      cursor: url('/assets/img/cursor/mouse.png'), auto !important;
    }
  }

  &.cursorPen {
    &,
    canvas {
      cursor: url('/assets/img/cursor/pen.png'), auto !important;
    }
  }
}

.video {
  flex: 1 0 75%;
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  .svgWrap{
    position: absolute;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 70px);
  }

  video {
    width: 100%;
    height: calc(100% - 70px);
    &::-webkit-media-controls {
      display: none !important;
    }
    &::-webkit-media-controls-enclosure {
      display: none !important;
    }
    &::-webkit-media-text-track-display {
      display: none;
    }
  }

  .controlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1005;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 10px;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .processBar {
      left: 10px;
      width: calc(100% - 20px);
    }
  }
}
