.header {
	height: 71px;
	padding: 8px;

	.container {
		width: 80%;
		height: 100%;
		margin: 0 auto;
		img {
			width: 180px;
			height: 55px;
		}
	}
}

.mainBg {
	background: #F4F6F8;
	min-height: calc(100vh - 71px);

	.container {
		width: 80%;
		margin: 0 auto;
		padding: 16px;
	}
}

.resultBg {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 2em;
	background: #BDE4EA;
	min-height: calc(100vh - 71px);
}

.description {
	margin: 1em auto;
	font-size: .8em;
	font-weight: 700;
	text-align: center;
}

.image {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	aspect-ratio: 900 / 488;
	background: white;
	outline: 5px solid #315D83;
	text-align: center;
	width: 60%;
	max-height: 70vh;
	margin-bottom: 24px;
	
	img {
		height: 100%;
		max-width: 100%;
	}

	.name {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background: #315D83;
		color: white;
		font-size: 50%;
		padding: 2px 8px;
		border-radius: 0px 0px 8px 8px;
		white-space: nowrap;
	}
}