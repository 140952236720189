
.selectPagesSection {
    padding-top: 1em;
    width: 90%;
    height: 100%;
  
    .title {
      display: flex;
      align-items: center;
      font-size: .7em;
      font-weight: 700;
      color: #212B36;
      margin-bottom: .3em;
    }
  
    .alreadySelected {
      color: #7A310A;
      font-size: .7em;
      background: #FFA235;
      border-radius: .5em;
      padding: .4em .6em;
      margin-left: 1em;
    }
  
    .pagesContainer {
      display: flex;
      flex-wrap: wrap;
      row-gap: .3em;
      column-gap: 1%;
      height: 100%;
      overflow-y: auto;
    }
  
    .pageCard {
      background: white;
      padding: 0em .5em .5em .5em;
      border: 2px solid #C4CDD5;
      border-radius: .5em;
      font-size: 1em;
      font-weight: 500;
      color: #212B36;
      width: 19%;
      &.selected {
        background: #FFD285;
        border: 2px solid #B7601A;
      }
    }
  
    .thumbnail {
      display: flex;
      span {
        width: 50%;
        img {
          width: 100%;
        }
      }
      &.rightToLeft {
        direction: rtl;
      }

      .fakeImg {
        display: inline-block;
        width: 100%;
        background-color: #C4CDD5;
        aspect-ratio: 1 / 1.25;
      }
    }
  }