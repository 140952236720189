.buttonGroup {
  display: flex;
  align-items: center;
}
.division_line {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 10px 0;
}
