.reader {
  display: flex;
  flex: 1;
  height: 85%;
  overflow: hidden;

  &.top {
    flex-direction: column-reverse;
  }

  &.bottom {
    flex-direction: column;
  }

  &.left {
    flex-direction: row-reverse;
  }

  &.right {
  }

  &.teacherMode {
    height: 100%;
  }

  &.studentMode {
    height: 100%;
  }
  &.otherMode {
    height: 100%;
  }
}


