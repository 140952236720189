@import "../../../style/media";

$fontFamily: "Microsoft JhengHei", "Montserrat", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.loginPopup__background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $fontFamily;
  transition: opacity 0.5s;
  opacity: 1;
  overflow: hidden;

  &.active {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .loginPopup__box {
    min-width: 300px;
    max-width: 657px;
    width: 100%;
    padding: 18px;
    background-color: white;
    border-radius: 0.5rem;
    margin: auto;
    position: relative;
    z-index: 1001;

    .loginPopup__content {
      display: flex;

      .loginPopup__box__left,
      .loginPopup__box__right {
        width: 50%;
        padding: 0px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      .loginPopup__box__right {
        border-left: solid 1px rgba(145, 158, 171, 0.24);
      }

      .loginPopup__box__left {
        display: flex;
        flex-direction: column;
        align-items: center;

        .naniAccount,
        .tip {
          line-height: 24px;
          font-size: 14px;
        }

        .naniAccount {
          color: #454b5c;
          font-weight: 500;
          letter-spacing: 0.75 px;
        }

        .tip {
          color: #919eab;
        }
      }

      .loginPopup__box__right {
        .onePass {
margin-bottom: 10px;
        }
        color: #454b5c;
        .qrcode {
          width: 80%;
          height: 80%;
        }
        p {
          all: unset;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
        .timeLimit {
          font-size: 14px;
        }
        button {
          all: unset;
          font-size: 11px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          .again {
            margin: 0 5px;
            font-size: 16px;
            font-weight: 900;
          }
          &:hover {
            color: #ec7963;
          }
        }
      }

      hr {
        width: 100%;
        border: solid 1px rgba(145, 158, 171, 0.24);
        margin: 32px 0px;
      }
    }

    @include phone {
      padding: 90px 30px 80px;
      width: 95%;
    }

    .loginPopup__close {
      position: relative;
      width: 20px;
      height: 20px;
      right: -100%;
      transform: translateX(-20px);
      z-index: 1001;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .crossLine__one,
      .crossLine__two {
        background-color: #a1a4b1;
        width: 2px;
        height: 23px;
        position: absolute;
        transform-origin: center;
      }

      .crossLine__one {
        transform: rotate(-45deg);
      }

      .crossLine__two {
        transform: rotate(45deg);
      }

      &:hover {
        .crossLine__one,
        .crossLine__two {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
          transition: all 0.35s cubic-bezier(0.25, 0.8, 0.25, 1);
          background-color: #ec7963;
        }
      }
    }

    .loginPopup__message {
      margin: 15px auto;
      padding: 10px;
      text-align: center;
      background-color: #f8bf01;
      color: #fff;
      border-radius: 5px;
      font-size: 0.8rem;
    }

    h5 {
      width: 100%;
      text-align: center;
      color: #212b36;
      font-size: 22px;
      margin-right: 15px;
      padding-bottom: 24px;
      margin: 0;
      display: inline-block;
      border-bottom: 2px solid transparent;
    }

    .loginPopup__btn {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #fff;
      padding: 0.75rem 0.85rem !important;
      border-radius: 8px !important;
      font-weight: 700;
      transition: 0.35s;
      height: 36px;
      img {
        width: 24px;
        margin-right: 10px;
      }

      &.loginPopup__btnblue {
        background-color: #4582ec;
        border-color: #4582ec;

        &:hover {
          background-color: #0950ca !important;
          border-color: #003aa5 !important;
        }
      }

      &.loginPopup__btnlogin {
        background-color: #ec7963 !important;
        border-color: #3d3d3d;
        margin-top: 15px;

        &:hover {
          background-color: #ec7963 !important;
          border-color: #ec7963 !important;
        }
      }
    }

    .loginPopup__inputbox {
      width: 100%;
      position: relative;

      .icon {
        position: absolute;
        left: 10px;
        top: 17.5px;
      }

      .loginPopup__attion {
        color: #fff;
        font-size: 0.8rem;
      }

      input {
        width: 100%;
        padding: 10px 5px 8px 40px;
        font-family: $fontFamily;
      }

      span {
        display: block;
        color: #dc3545;
        font-weight: 700;
        font-size: 0.7rem;
        height: 16px;
      }
    }
  }
}
.login__setting {
  width: 100%;
  display: flex;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  color: #a1a4b1;
  .loginPopup__remeber {
    display: flex;
    align-items: center;
    cursor: pointer;
    .mark {
      position: absolute;
      color: white;
      font-weight: 900;
      transform: translateX(2px);
    }
    input {
      all: unset;
      width: 15px;
      height: 15px;
      border: solid 1px #a1a4b1;
      border-radius: 5px;
      margin-right: 6.5px;
      &:checked {
        background-color: #ec7963;
      }
    }
  }

  .loginPopup__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a1a4b1;
    width: 120px;
    a {
      width: 50%;
      text-decoration: none;
      color: #a1a4b1;
      & + a {
        border-left: 1px solid #a1a4b1;
        padding-left: 10px;
      }
    }
  }
}

.btnMoe {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #82b8c0;
  height: 36px;
  border-radius: 8px;
  &:hover {
    opacity: 0.9;
  }

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    line-height: 1.5;
    font-weight: 700;

    img {
      margin-right: 5px;
    }
  }
}

.btnMoe2 {
  border: solid 1px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4582ec;
  border-color: #4582ec;
  padding: 0.75rem 0.85rem;
  border-radius: 10px;
  color: #fff;
  x > a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    line-height: 1.5;
    font-weight: 700;

    img {
      margin-right: 5px;
    }
  }
}

.CountDownTimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .againButton {
    font-size: 11px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  p {
    all: unset;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}

@media (max-width: 799px) {
  .loginPopup__background {
    .loginPopup__box {
      width: 322px;
      padding-top: 12px;
      padding-bottom: 24px;
      .loginPopup__content {
        flex-direction: column;
        align-items: center;
        .loginPopup__box__left {
          width: 100%;
        }
        .loginPopup__box__right {
          display: none;
        }
      }
    }
  }
}

.loginPopup__background__qrcode {
  .loginPopup__box {
    width: 322px;
    padding-top: 12px;
    padding-bottom: 24px;
    .loginPopup__content {
      flex-direction: column;
      align-items: center;
      .loginPopup__box__left {
        width: 100%;
      }
      .loginPopup__box__right {
        display: none;
      }
    }
  }
}

.qrcodeLoginHint {
  width: 80%;
  color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  line-height: 150%;
}
