.aboutTheAuthor {
  display: flex;
  flex-flow: row-reverse;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ruby {
    rt {
      transform: translateX(10px);
    }
  }

  .tab {
    flex: .3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .list {
      width: 7vmin;
      font-size: 3vmin;
      border-radius: 10px;
      color: #000;
      background-color: #5eeae3;
      padding: 2vmin;
      margin: 1vmin 0;
      text-align: center;
      cursor: pointer;

      &.active {
        background-color: #f98fb8;
        cursor: default;
      }
    }
  }

  .sound {
    flex: .1;
  }

  .image {
    flex: .8;
    padding: 0 1vmin;

    img {
      width: 100%;
    }
  }

  .word {
    flex: 2 2 0;
    overflow-x: auto;
    padding: 0 3vmin;
    // height: 100%;
    writing-mode: vertical-rl;
    direction: ltr;
    transform-style: preserve-3d;
  }
}