.ql-editor {
    font-size: 36px;
    min-height: 100px !important;
    max-height: 40vmin;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
}

.ql-size-24px {
    font-size: 24px;
}
.ql-size-36px {
    font-size: 36px;
}
.ql-size-48px {
    font-size: 48px;
}
.ql-size-72px {
    font-size: 72px;
}
.ql-size-96px {
    font-size: 96px;
}
