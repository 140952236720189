.pageToolDragZone {
  // z-index: 10000;
  &.show {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .pageTool {
    position: absolute;
    bottom: 1%;
    right: 3%;
    z-index: 1300;
    display: flex;
    align-items: flex-end;

    .pageToolButton {
      margin-left: 5px;

      button {
        pointer-events: none;
      }

      button:focus {
        background-color: initial;
      }
    }

    .container {
      position: relative;
      width: 20vmin;
      border-radius: 10px;
      background-color: rgba($color: #000000, $alpha: 0.7);
      display: none;
      overflow: hidden;
      margin: auto;
      padding-top: 20px;

      &.show {
        display: block;
      }

      .closeBtn {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #fff;
        cursor: pointer;
      }

      .content {
        overflow-x: hidden;
        overflow-y: auto;
        height: 80%;
        padding: 10px;

        .btn {
          display: flex;
          padding: 5px 3px;
          border: 1px solid #deebf6;
          background-color: #0070c0;
          //   background: radial-gradient(circle at 50% -816%, #8bbee6 93%, #2b8cd1 50%);
          border-radius: 5px;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          & + .btn {
            margin-top: 10px;
          }
        }

        .icon {
          width: 2vmin;
          height: 2vmin;
          color: white;
          padding-right: 10px;
        }

        .text {
          font-size: 3vmin;
          color: #fff;
        }
      }
    }
  }
}
