.painterOptionSelector {
	margin: 10px 0;
	.radio{
		margin: 5px;
		label{
			color: #fff;
			cursor: pointer;
			&	+ label {
				margin-left: 10px;
			}
			&.disabled{
				cursor: not-allowed;
			}
		}
	}
	.buttonGroup{
		display:flex;
		.button{
      min-width: 4vmin;
      width: 4vmin;
      height: 4vmin;
			padding: 5px 0;
			border-radius: 5px;
      &.active{
        background-color: #3584FF;
			}
			&.disabled{
				cursor: not-allowed;
			}
		}
	}
}