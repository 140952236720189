.formGroup{
  display: flex;
  padding: 5px 0;
  width: 100%;
  &.vertical{
    flex-direction: column;
  }
  .label{
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
  + .formGroup{
    padding-left: 15px;
  }
}