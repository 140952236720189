.audioComponent{
  text-align: right;
  .addSrcButton{
    width: auto;
    padding: 20px;
    background-color: steelblue;
    font-size: 1.4vmin;
    color: #fff;
    &:focus{
      color: #fff;
    }
  }
  .input{
    > label {
      display: flex;
      justify-content: space-between;
    }
  }
}