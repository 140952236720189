.shapeSelector {
	margin: 10px 0;
	.fillRadio{
		margin: 5px;
		label{
			color: #fff;
			cursor: pointer;
			&	+ label {
				margin-left: 10px;
			}
		}
	}
	.buttonGroup{
		display:flex;
		.button{
			min-width: 5vmin;
			width: 5vmin;
			&.active {
				background-color: #3584FF;
			}
		}
	}
}