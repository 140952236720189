@import '../../style/media';

.popover {
  position: absolute;
  width: 70vmin;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;

  &.oneBtn {
    min-width: 18vmin;

    .content {
      .btn {
        width: 98%;
        margin: 0 auto;
      }
    }
  }

  &.twoBtn {
    min-width: 36vmin;

    .content {
      .btn {
        width: 48%;
      }
    }
  }

  @include phone {
    width: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fff;
    cursor: pointer;
  }

  &.top {
    top: 110%;
    left: 50%;
    bottom: initial;
    right: initial;
    transform: translateX(-50%);
  }

  &.bottom {
    bottom: 110%;
    left: 50%;
    top: initial;
    right: initial;
    transform: translateX(-50%);
  }

  &.left {
    top: 50%;
    left: 110%;
    bottom: initial;
    right: initial;
    transform: translateY(-50%);
  }

  &.right {
    top: 50%;
    right: 110%;
    bottom: initial;
    left: initial;
    transform: translateY(-50%);
  }

  .title {
    font-size: 3vmin;
    padding: 5px 0;
    font-weight: 700;
    text-align: center;
    color: #fff;
    height: 20%;
  }

  .content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 60vh;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    margin-bottom: 10px;

    .btn {
      display: flex;
      width: 32%;
      align-items: center;
      justify-content: center;
      padding: 5px 3px;
      border: 1px solid #deebf6;
      background-color: #0070c0;
      // background: radial-gradient(circle at 50% -816%, #8bbee6 93%, #2b8cd1 50%);
      border-radius: 5px;
      cursor: pointer;
      margin: 4px 0;

      &:not(:nth-child(3n)) {
        margin-right: 2%;
      }
    }

    .icon {
      width: 2vmin;
      height: 2vmin;
      color: white;
      padding-right: 10px;
    }

    .text {
      font-size: 3vmin;
      color: #fff;
    }
  }
}
