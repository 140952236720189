.boxModalMask {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 99999;
}

.boxModal {
    position: fixed;    
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)!important;
    display: flex;
    flex-direction: column;
    background-color: #D9F1F8;
    border-radius: 20px;
    box-shadow: 2px 2px 10px #333;
    overflow: hidden;

    @media screen and (max-width: 576px) {
        width: calc(100% - 24px);
    }
    @media screen and (max-height: 576px) {
        width: auto;
        height: calc(100% - 60px);
    }
}

.controlHead {
    padding: 16px 24px;
    background-color: #315D83;
    @media screen and (max-height: 576px) {
        padding: 8px 12px;
    }
    @media screen and (min-width: 1440px) {
        padding: 1.111lvw 1.667lvw;
    }
}

.title {
    color: white;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    margin: 0 auto;
    @media screen and (max-width: 576px), (max-height: 576px) {
        font-size: 18px;
        line-height: 1.556;
    }
    @media screen and (min-width: 1440px) {
        font-size: 2.222lvw;
    }
}

.content {
    padding: 24px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    @media screen and (max-height: 576px) {
        padding: 12px 32px;
    }
    @media screen and (min-width: 1440px) {
        padding: 1.667lvw 2.222lvw;
    }
}

// 按鈕
.bottom {
    padding: 16px 24px 24px;
    @media screen and (max-height: 576px) {
        padding: 8px 24px 12px;
    }
    @media screen and (min-width: 1440px) {
        padding: 1.11lvw 1.67lvw 1.67lvw;
    }
}

%bottomWhiteButton {
	border: none;
	background-color: #F4F6F8;
    width: auto;
    height: auto;
    padding: 10px 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    border-radius: 12px;
	img {
		vertical-align: text-bottom;
		margin-left: 12px;
	}
    @media screen and (max-width: 576px), (max-height: 576px) {
        font-size: 18px;
        line-height: 1.556;
	}
	@media screen and (min-width: 1440px) {
        padding: 0.694lvw 1.111lvw;
        font-size: 1.667lvw;
	}
}

.originalBottom {
	button {
		color: #7A310A;
		background: #FFA235;
		border-bottom: 5px solid #B7601A;
		cursor: pointer;
		@extend %bottomWhiteButton
	}
}

.widthFull {
	button {
		width: 100%;
	}
}