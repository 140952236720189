.backdrop {
	background: #00000099;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	.text {
		color: white;
		font-size: 2em;
		font-weight: 700;
	}
}