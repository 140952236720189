.bookContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvasWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;

  &.covered {
    z-index: 12;
  }
}
