.subtitles {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 8vmin;
  font-family: Arial, Microsoft JhengHei, 微軟正黑體, -apple-system, BlinkMacSystemFont;
  text-align: center;
  line-height: 1.2;
  font-weight: bold;

  &.large {
    font-size: 9vmin;
  }

  &.medium {
    font-size: 8vmin;
  }

  &.small {
    font-size: 7vmin;
  }

  &.Down {
    bottom: 68px;
    z-index: 1005;
  }

  &.Up {
    top: 0px;
  }
}
