// @import '../../../style/media';
@import '../WordsMeaningButtonGroup/index.module.scss';
.buttonGroup {
  display: flex;
  border-radius: 20px;
  border: 3px solid #48bad4;
  overflow: hidden;
  button {
    margin: 1px 0;
    border-radius: 0;
    background-color: #c2f9fe;
    &.singleRepeat{
      background-color: #fff;
      &:hover{
        background-color: #fff2d9;
      }
    }
  }
}
