.selectWrapper{
  position: relative;
  .icon{
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    height: 100%;
    color: #000;
    cursor: pointer;
  }
  select{
    display: inline-block;
    appearance: none;
    padding: 5px 10px;
    margin: 0 auto;
    width: 100%;
    text-align-last:center;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, Arial, Microsoft JhengHei, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, Helvetica, sans-serif;
  }  
}
