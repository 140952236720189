@mixin btn {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    // text-align: center;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
    background-color: #fba66f;
    color: #fff;
    cursor: pointer;
    margin-right: 5px;
}
.stickyContainer {
    position: absolute;
    top: -100px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &.fullDragRange {
        width: 100%;
        height: 100%;
    }
}

.sticky {
    position: absolute;
    width: 40%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 20px 0px;
    padding: 20px;
    border-radius: 8px;
}

.stickyContent {
    height: 100%;
    overflow-y: 'auto';
    .topTools {
        height: 10%;

        display: flex;
        align-items: center;
        margin-bottom: 1vmin;
        justify-content: space-between;
        & > div{
            display: flex;
        }
        .closeBtn {
            @include btn;
            border: #b5b2b2 solid;
            background-color: #ffffff;
            color: #000;
            margin-right: 0px;
            img {
                width: 25%;
                height: 100%;
            }
            .closeBtnText {
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .title {
            @include btn;
            width: 150px;
            cursor: default;
            border-radius: 0;
            svg{
                margin-right: 8px;
            }
            img {
                width: 25%;
                height: 100%;
            }
            .titleText {
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .addBtn{
            @include btn;
            background-color: #29a2e8;
        }
    }
    .htmlTools {
        background-color: #ffffff;
    }
    .editor {
        background-color: #fff;
        height: 70%;
    }
    .itemList{
        max-height: 210px;
        padding:5px 10px 5px 0;
        overflow: auto;
        .item{
            align-items: stretch;
            display: flex;
            margin-bottom: 1vmin;
            .delBtn{
                background-color: #fa8029;
                color: #fff;
                width: 25px;
                padding: 10px 5px;
                border-radius: 8px;
                text-align: center;
                margin-right: 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .linkInfo{
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .linkRow{
                    display: flex;
                    margin: 5px 0;
                    input{
                        flex: 1;
                        box-shadow: -1px 1px 3px -1px red inset;
                        border-radius: 4px;
                        border:1px solid #f39c9d;
                    }
                }
            }
        }
    }
    .bottomTools {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        div {
            @include btn;
            background-color: #fa7e6b;
            margin-right: 0;
            margin-left: 5px;
        }
    }
}
