.radio {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  .dot {
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #e6e6e6;
  }
  .label {
    font-size: 20px;
    padding-left: 5px;
  }
  &.active {
    .dot:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: #cc0000;
    }
  }
}
