.annotationModal.hide {
    opacity: 0;
}
.addNewAnnotation {
    display: flex;
    height: 90px;
    background: white;
    padding: 16px;
    // border: 3px solid #EC7963;
    border: 1px solid #D5D7DE;
    border-radius: 8px;
    margin-bottom: 12px;
    >img {
        margin-right: 16px;
        height: 50px;
        width: initial;
    }

    .inputArea {
        position: relative;
        width: 70%;
        .inputBtns {
            position: absolute;
            right: 0;
            bottom: -15px;
            button {
                min-width: initial;
                width: 2.5rem;
                height: 1.8rem;
                padding: 8px;
                background: #FAFAFC;
                border-radius: 8px;
                white-space: nowrap;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            }
            button:nth-child(2) {
                margin-left: 4px;
            }
            span {
                font-size: 11px;
                color: #454B5C;
            }
        }
        span {
            display: block;
            color: rgba(0, 0, 0, 0.54);
            font-size: 0.875rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            margin-top: 4px;
        }
    }
}