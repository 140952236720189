.readAloud {
  // 內容
  .listContent {
    height: 90%;
    font-size: 8vmin;
    overflow: auto;
    font-family: cursive;
    direction: rtl;

    .content {
      height: 100%;
      writing-mode: vertical-rl;
      direction: ltr;

      // 字型 全域設定
      ruby {
        margin: 1vmin 0;

        rt {
          font-family: BopomofoRuby;
          font-size: 2.5vmin;
          font-weight: 700;
          transform: translateX(10px);
        }
      }

      .track {
        padding: 10px 0;
        color: #000;
        font-size: 8vmin;
        position: relative;
        padding: 0 4vmin;
        line-height: 2.2;
        cursor: pointer;

        span {
          width: 100%;
        }

        &.show {
          background-color: #f2e205;
          padding-right: 5vmin;
          padding-left: 2vmin;
        }
      }
    }
  }

  // 播放器
  .musicBar {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}