.switchButton{
  display: inline-flex;
  position: relative;
  background-color: #fff;
  width: 32px;
  height: 20px;
  border-radius: 30px;
  background-color: #bbb;
  cursor: pointer;
  .checkbox{
    opacity: 0;
  }
  &:after{
    content:'';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(10%) translateY(-50%);
    width: 17px;
    height: 17px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15), 0px 2px 0.5px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    transition: .3s;
  }
  &.active{
    background-color: #EBB74F;
    &:after{
      transform: translateX(80%) translateY(-50%);
    }
  }
}
