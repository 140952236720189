@import '../../style/media';

.index {
  display: flex;
  justify-content: center;

  .container {
    width: 100%;
    box-shadow: 0px 0px 10px #ccc;
    padding: 20px 0;
  }

  .list-container {
    width: 100%;
    max-width: 1200px;
    margin: 15px auto;
    padding: 1%;
    list-style: none;
    // border: 0.2rem solid #ccc;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    // grid-gap: 20px;
    display: flex;
    // align-items: stretch;
    flex-wrap: wrap;   
  }

  .default {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 8vmin;
    }

    .sub_title {
      margin: 5px 0;
      font-size: 4vmin;
    }

    .go_box {
      margin: 20px 0;
      width: 100%;
      display: flex;
      justify-content: center;

      .go_onemall {
        padding: 5px 10px;
        color: #fff;
        background-color: #ccc;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          color: #000;
          background-color: #f4f4f4;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }

  .version-info {
    text-align: right;
    padding-right: 10px;
  }

}

.dialogButton{
  border: solid;
  color: blue;
}

.progressContent{
  width: 25vh;
  .text{
    display: flex;
    align-items: center;
  }
  .progress{
    width:100%
  }
}

.loadingContent{
  width: 25vh;
  .text{
    text-align: center;
  }
  .loading{
    text-align: center;
    padding-top: 20px;
  }
}

.filter{
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin:0 auto ;
  button{
    width: auto;
    height: auto;
  }
}

.noResult{
  max-width: 1200px;
  margin: 25px auto;
  font-size: 35px;
  text-align: center;
}