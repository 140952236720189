@import '../../../style/media';

.buttonGroup {
  display: flex;
  flex-wrap: wrap;

  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 3px 0;
    cursor: pointer;
    font-size: 2.5vmin;
    font-weight: bold;
    color: #323232;
    background-color: #fff;
    appearance: none;
    border: 0px;
    border-radius: 25px;
    font-family: '微軟正黑體';
    @media screen and (max-width: 1280px) {
      font-size: 2.2vmin;
    }
    @include tablet {
      font-size: 2.2vmin;
    }

    &.active,
    &:hover {
      background-color: #fff2d9;
    }

    &:disabled {
      color: #8f8f8f;
      background-color: #d9d9d9;
      cursor: not-allowed;
    }
  }
}
