.textPanel{
  color: #fff;
  font-weight: bold;
  .group {
    padding: 5px 0;
    .select{
      margin: 10px 0;
      width: 100px;
      min-width: 70px;
    }
    .title{
      font-size: 15px;
    }
  }
  .textStyles{
    display: flex;
    align-items: center;
    .textStyle{
      display: inline-flex;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 5px;
      margin-left: 10px;
      border: 2px solid #fff;
      border-radius: 5px;
      cursor: pointer;
      &.active{
        background-color: #fff;
        color: #444;
      }
    }
  }
}
