.miniWindow {
	position: absolute;
	right: 30px;
	bottom: 95px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 248px;
	// height: 322px!important;
	background: #FFF3D6;
	border: 1px solid #FFD285;
	border-radius: 16px;
	padding: 16px 24px;
	color: #7A310A;
    font-weight: 700;
    opacity: 1;
	cursor: move;

    &.show {
        opacity: 1;
    }

	& > span {
	  font-size: 24px;
	  margin-bottom: 8px;
	}
	button {
	  width: 104px;
	  min-width: 104px;
	  height: 46px;
	  margin-top: 8px;
	  span {
        font-weight: 700;
		font-size: 18px;
	  }
	}
  }