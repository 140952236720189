.extendedContentContainer {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #f4f4f4;
  font-family: Arial, Microsoft JhengHei, 微軟正黑體, -apple-system, BlinkMacSystemFont;
  transition: opacity .5s;

  .contentView {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    color: #000;
    background-color: #fff;
    padding: 5px 10px;
    height: 100%;

    & > div {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    // 數學套件的DIV高度設置
    & > div > div {
      height: 100%;
    }

    .svgContent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      pointer-events: none;
      overflow: hidden;
      direction: ltr;

      svg {
        pointer-events: none;
      }
    }

    // 團體互動工具用
    &.noPadding {
      padding: 0;
    }
  }



  // 個別小視窗樣式 from ExtendedContentType
  &.listening {
    .extendedButtonBox {
      width: 100%;
      display: flex;

      & > div {
        width: 50%;
      }
    }
  }

  &.synopsis {
    .contentView {
      overflow: hidden;
    }
  }

  &.wordGrid {
    overflow-y: hidden;
  }

  // 轉檔多出來的br標籤
  br {
    display: none;
  }

  &.cursorDrag {
    &,
    canvas {
      cursor: url('/assets/img/cursor/mouse.png'), auto !important;
    }
  }

  &.cursorPen {
    &,
    canvas {
      cursor: url('/assets/img/cursor/pen.png'), auto !important;
    }
  }

  &.cursorHighlighter {
    &,
    canvas {
      cursor: url('/assets/img/cursor/marker.png'), auto !important;
    }
  }

  &.cursorShape {
    &,
    canvas {
      cursor: url('/assets/img/cursor/shape.png'), auto !important;
    }
  }

  &.cursorLine {
    &,
    canvas {
      cursor: url('/assets/img/cursor/line.png'), auto !important;
    }
  }

  &.cursorDelete {
    &,
    canvas {
      cursor: url('/assets/img/cursor/eraser.png'), auto !important;
    }
  }

  &.cursorSelect {
    &,
    canvas {
      cursor: url('/assets/img/cursor/select.png'), auto !important;
    }
  }
}

.unfocus {
  &:focus {
    background-color: initial;
    color: #414141;
  }
}