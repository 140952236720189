.extendedContentButton {
    width: 100%;
    height: 5%;
    margin: 5px 0;
    display: flex;
    align-items: flex-end;

    .left {
        display: inline-flex;
    }

    .right {
        display: inline-flex;
    }

    .button {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        color: #fff;
        background-color: #6dd1cf;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: #999;
            background-color: #99FFFF;
        }
        &.active {
            color: #999;
            background-color: #99ffff;
        }
    }

    .scrollBtn {
        width: 70px;  
    }

}
