.extended_content_tools {
	position: relative;
	width: 100%;

	.toolsWrapper {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		overflow-x: auto;
		overflow-y: hidden;

		button {
			color: #414141;

			img {
				width: 50%;
			}
		}
	}

	.leftbox {
		display: flex;
		align-items: center;
	}

	.rightbox {
		display: flex;
		align-items: center;
	}

	.toolBtn {
		position: relative;

		.painterPanel {
			left: 0;
			transform: initial;
		}
	}
}