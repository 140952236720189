.musicPlayer {
  display: none;
  position: relative;
  // background-color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  width: 380px;
  height: 45px;
  padding: 10px 5px 3px 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 12px #999;
  transition: .3s;
  &.show{
    display: flex;
  }
  &.settingMode{
    border-radius: 0px;
    transition: .3s;
  }
  .settingPanel {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 0;
  }
  .controlsButton{
    font-size: 20px;
    height: 100%;
    color:#000;
    .close{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: #cc0000;
      color: #fff;
      font-size: 16px;
      width: 20px;
      height: 20px;
    }
    .settingButton{
      width: 100%;
      height: 100%;
      font-size: 18px;
      &.active{
        color: #cc0000;
      }
    }
    &.isShowSetting:after{
      content:'';
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
  .volumeBar{
    color: #000;
    height: 100%;
    margin-left: 5px;
    button{
      height: 100%;
      font-size: 20px;
      color: #000;
    }
  }
  .timeInfo{
    position: absolute;
    bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color:#000;
  }
}
.processBarWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .processBar {
    position: relative;
    transform: none;
  }
}

.music {
  flex: 1 0 70%;
  position: relative;
  align-content: center;
  z-index: 1;
  audio {
    width: 100%;
  }
  .top {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
  }
  .bottom {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
