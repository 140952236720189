.contextMenuBox {
  display: none;
  top: -100%;
  left: -100%;
  opacity: 0;
  width: 180px;
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 2px 2px 3px #aaaaaa;
  color: #fff;
  font-weight: bold;
  overflow: hidden;
  z-index: 100000;
  &.show {
    opacity: 1;
    display: block;
  }
}

.contextMenuItem {
  padding: 3px 5px;
  cursor: pointer;
  &:hover {
    background-color: #0a84ff;
  }
}
