.bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #BDE4EA;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left bottom;
	font-size: 2em;
	overflow-y: auto;
	padding: .5em;

	&.centered{
		justify-content: center;
	}

    .headText {
		color: #1890ff;
		font-weight: 700;
		margin-bottom: .75em;
	}
}

// Popup 內容
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 480px;
	text-align: center;
	height: 10%;
	@media screen and (max-height: 576px) {
		flex-direction: row;
		width: auto;
	}
	@media screen and (min-width: 1440px) {
		width: 33.333lvw;
	}
}

.contentImg {
	width: 200px;
	@media screen and (max-height: 576px) {
		width: 45svh;
		max-width: 200px;
	}
	@media screen and (min-width: 1440px) {
		width: 13.889lvw;
	}
}

.contentText {
	font-size: 28px;
	font-weight: 500;
	line-height: 1.286;
	color: #637381;
	span {
		color: #D32613;
	}
	@media screen and (max-height: 576px) {
		white-space: nowrap;
	}
	@media screen and (min-width: 1440px) {
		font-size: 1.944lvw;
	}
}

