.vocabularyExample {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .tabPanelWrapper {
    height: 100%;
    overflow: hidden;
  }

  .tabPanel {
    height: 100%;
    overflow-y: auto;

    &.vocabulary {
      display: flex;

      .left {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .word {
          margin-bottom: 30px;
          font-weight: bold;
          white-space: nowrap;
          color: #3374aa;
        }

        .icon {
          width: 80px;
        }
      }

      .right {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    &.example {
      .title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 70px;
          margin-right: 30px;
          background-image: url('/assets/icon/vocabulary/button.svg');
          background-size: cover;
          color: #fff;
          font-weight: bold;
          // font-size: 18px;
          cursor: pointer;
        }

        .info {
          display: flex;
          align-items: flex-start;
          // font-size: 8vmin;
          .word {
            // font-size: 11vmin;
            margin-left: 10px;

            .vocabularyWord {
              display: flex;
              align-items: center;
              white-space: nowrap;
              // font-size: 11vmin;
              font-weight: bold;
              color: #3374aa;

              .vocabularyInfo {
                margin: 0;
                padding: 0;
              }

              span.vocaWord {
                margin-right: 15px;
              }
            }

            .vocaInfo {
              display: flex;
              align-items: center;

              .tw {
                opacity: 0;
                color: #3374aa;

                &.active {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .exampleSentence {
        display: flex;
        padding: 15px 0;

        .exampleSentenceContent {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
        }

        p {
          margin: 0;
          // font-size: 8vmin;
        }

        .tw {
          opacity: 0;
          color: #3374aa;

          &.active {
            opacity: 1;
          }
        }
      }
    }

    &.plus,
    &.extra {
      display: block;
      overflow-y: auto;

      p {
        margin: 0;
        line-height: 1.4em;
      }

      .title {
        display: inline-block;
        padding-bottom: 10px;
        margin-bottom: 10px;
        width: 100%;
        // font-size: 6vmin;
        font-weight: 500;
        border-bottom: 1px solid #ccc;
      }

      .content {
        width: 100%;
        div {
          margin-bottom: 5px;
          // font-size: 8vmin;
        }
      }

      .contentIcon2 {
        padding: 5px;
        background-color: #3d95d1;
        color: #ffffff;
        border-radius: 10px;
        font-weight: bold;
        margin-right: 15px;
        vertical-align: middle;
        justify-content: center;
        display: inline-flex;
        align-items: center;
        align-self: flex-start;
        justify-content: center;
        margin-top: 3vmin;

        &.multiplyMeaning {
          display: inline-flex;
          align-items: center;
          background-color: #14A574;
          font-size: 3.5vmin;
          height: 9vmin;
          width: 14%;
          margin-top: 4vmin;
        }

        &.explanation {
          display: inline-flex;
          align-items: center;
          background-color: #b355a0;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.idiom {
          display: inline-flex;
          align-items: center;
          background-color: #3d95d1;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.usage {
          display: inline-flex;
          align-items: center;
          background-color: #05a3e1;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.note {
          display: inline-flex;
          align-items: center;
          background-color: #3c95d1;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.sentence {
          display: inline-flex;
          align-items: center;
          background-color: #818285;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.compare {
          display: inline-flex;
          align-items: center;
          background-color: #eb851d;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.confuse {
          display: inline-flex;
          align-items: center;
          background-color: #f16775;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.sentenceBlue {
          display: inline-flex;
          align-items: center;
          background-color: #3c95d1;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }
      }

      .contentIcon3 {
        padding: 5px;
        background-color: #3d95d1;
        color: #ffffff;
        border-radius: 10px;
        font-weight: bold;
        margin-right: 15px;
        vertical-align: middle;
        justify-content: center;
        display: inline-flex;
        align-items: center;
        align-self: flex-start;
        justify-content: center;
        margin-top: 3vmin;

        &.multiplyMeaning {
          display: inline-flex;
          align-items: center;
          background-color: #14A574;
          font-size: 3.5vmin;
          height: 9vmin;
          width: 14%;
          margin-top: 4vmin;
        }

        &.explanation {
          display: inline-flex;
          align-items: center;
          background-color: #b355a0;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.idiom {
          display: inline-flex;
          align-items: center;
          background-color: #3d95d1;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.usage {
          display: inline-flex;
          align-items: center;
          background-color: #05a3e1;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.note {
          display: inline-flex;
          align-items: center;
          background-color: #3c95d1;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.sentence {
          display: inline-flex;
          align-items: center;
          background-color: #818285;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.compare {
          display: inline-flex;
          align-items: center;
          background-color: #eb851d;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.confuse {
          display: inline-flex;
          align-items: center;
          background-color: #f16775;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }

        &.sentenceBlue {
          display: inline-flex;
          align-items: center;
          background-color: #3c95d1;
          font-size: 4.5vmin;
          height: 12vmin;
          width: 14%;
        }
      }

      .contentIcon {
        padding: 5px;
        background-color: #3d95d1;
        color: #ffffff;
        border-radius: 10px;
        font-weight: bold;
        font-size: 9vmin;
        margin-right: 15px;
        vertical-align: middle;

        &.multiplyMeaning {
          display: inline-flex;
          align-items: center;
          background-color: #14A574;
          font-size: 3.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.explanation {
          display: inline-flex;
          align-items: center;
          background-color: #b355a0;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.idiom {
          display: inline-flex;
          align-items: center;
          background-color: #3d95d1;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.usage {
          display: inline-flex;
          align-items: center;
          background-color: #05a3e1;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.note {
          display: inline-flex;
          align-items: center;
          background-color: #3c95d1;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.sentence {
          display: inline-flex;
          align-items: center;
          background-color: #818285;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.compare {
          display: inline-flex;
          align-items: center;
          background-color: #eb851d;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.confuse {
          display: inline-flex;
          align-items: center;
          background-color: #f16775;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }

        &.sentenceBlue {
          display: inline-flex;
          align-items: center;
          background-color: #3c95d1;
          font-size: 4.5vmin;
          height: 9vmin;
          width: 14%;
        }
      }

      .example {
        display: flex;
        align-items: center;

        .exampleSound {
          align-self: flex-start;
          margin-top: 1vmin;
          min-height: 8vmin;
          min-width: 8vmin;
        }

        .exampleIcon {
          display: inline-flex;
          align-items: center;
          align-self: flex-start;
          justify-content: center;
          padding: 5px 10px;
          background-color: #cf5260;
          color: #ffffff;
          border-radius: 50px;
          margin: 5px;
          font-weight: bold;
          font-size: 6vmin;
          min-height: 10vmin;
          min-width: 10vmin;
        }

        .exampleContent {
          display: inline-block;
          padding: 0 5px;
        }
      }

      .exampleTranslation {
        padding-left: 19.5vmin;
      }
    }
  }

  .tabSelector {
    position: relative;
    display: flex;
    align-items: flex-end;

    .tabs {
      display: flex;
    }

    .tab {
      padding: 5px 10px;
      margin-right: 10px;
      color: #fff;
      background-color: #cbcccd;
      font-size: 32px;
      font-weight: bold;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      cursor: pointer;

      &.active {
        background-color: #64c8d1;
      }
    }

    .ctrlButtonGroup {
      display: flex;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      .ctrl {
        font-size: 30px;
        color: #64c8d1;
        padding: 5px;
        cursor: pointer;

        &.left {
          margin-right: 5px;
        }

        &.disabled {
          color: #999;
        }
      }
    }
  }

  .vocaSelector {
    position: relative;
    display: flex;
    padding: 5px 30px;
    background-color: #44b2ac;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 1px 10px 1px #ccc;
    white-space: nowrap;

    .ctrl {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: rgba(255, 255, 255, 0.5);
      font-size: 28px;
      cursor: pointer;

      &.left {
        left: 8px;
      }

      &.right {
        right: 8px;
      }

      &.hide {
        display: none;
      }
    }

    .contentWrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;

      .voca {
        flex: 1;
        padding: 0 15px;
        color: #fff;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;

        & + .voca {
          border-left: 1px solid #fff;
        }

        &.active {
          color: #feff02;
        }

        &.disabled {
          color: #999;
          pointer-events: none;
        }
      }
    }
  }

  .audioButton {
    display: flex;
    align-items: center;
    width: 60px;
    height: 100%;
  }

  &.large {
    font-size: 15vmin;

    .example,
    .plus,
    .extra {
      font-size: 12vmin;
    }
  }

  &.medium {
    font-size: 13vmin;

    .example,
    .plus,
    .extra {
      font-size: 10vmin;
    }
  }

  &.small {
    font-size: 11vmin;

    .example,
    .plus,
    .extra {
      font-size: 8vmin;
    }
  }
}

.controlsButton {
  position: absolute;
  top: 10px;
  right: 40px;
  height: 60px !important;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
  position: relative;

  .word {
    font-size: 4vmin;
    border-radius: 100%;
    background-color: rgb(59, 59, 59);
    color: #ffffff;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateY(15%);
  }
}

@media (max-width: 600px) {
  .vocabularyExample {
    .tabPanel {
      .content {
        .example {
          .contentIcon {
            width: 20%;
            .multiplyMeaning {
              
            }

            .confuse {
              width: 8%;
            }

            .sentenceBlue {
              width: 8%;
            }
          }

          .contentIcon2 {
            width: 20%;
            font-size: 8px;
            .content{
              width: 8%;
            }
            .multiplyMeaning {
              font-size: 8px;
              width: 8%;
            }

            .confuse {
              width: 8%;
            }

            .sentenceBlue {
              width: 8%;
            }
          }
        }
      }
      &.vocabulary {
        .left {
          justify-content: flex-start;
        }
      }
    }

  }

}

@media (min-width: 1024px) {
  .vocabularyExample {
    .tabPanel {
      .content {
        .example {
          .contentIcon {
            width: 150px;
            height: 100%;
            .multiplyMeaning {
              width: 50px;
              font-size: 8px;
            }

            .confuse {
              width: 50px;
            }

            .sentenceBlue {
              width: 50px;
            }
          }

          .contentIcon2 {
            width: 150px;
            height: 100%;
            .content{
              width: 50px;
            }
            .multiplyMeaning {
              width: 50px;
              font-size: 8px;
            }

            .confuse {
              width: 50px;
            }

            .sentenceBlue {
              width: 50px;
            }
          }
          .contentIcon3 {
            width: 170px;
            height: 100%;
            .content{
              width: 50px;
            }
            .multiplyMeaning {
              width: 50px;
              font-size: 8px;
            }

            .confuse {
              width: 50px;
            }

            .sentenceBlue {
              width: 50px;
            }
          }
        }
      }
      &.vocabulary {
        .left {
          justify-content: center;
        }
      }
    }

  }
}