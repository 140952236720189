.mobileAnnotation {
	position: fixed;
	background: #2F3649;//rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 1002;
	display: none;
	&.show{
		display: block;
	}
}

