.dragMask {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: absolute;
  z-index: 99999;
}

.dragModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important ;
  width: 45lvw;
  min-width: calc((640vmin / 900) * 100);
  border-radius: calc((20vmin / 900) * 100);
  overflow: hidden;
  background-color: #d9f1f8;
  box-shadow: 2px 2px 10px #333;
  z-index: 100000;
}

.draglHead {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: calc((16vmin / 900) * 100) calc((24vmin / 900) * 100);
  width: 100%;
  background-color: #315d83;
}

.dragTitle {
  color: white;
  font-size: calc((32vmin / 900) * 100);
  font-weight: 700;
  line-height: 1.5;
  margin: 0 auto;
}

.dragCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((27vmin / 900) * 100);
  height: calc((27vmin / 900) * 100);
  border-radius: 50%;
  box-shadow: 1px 1px 5px #333;
  background-color: #fff;
  color: #ec6464;
  cursor: pointer;

  .closeIcon {
    width: 60%;
    height: auto;
  }
}

.dragContent {
  flex-grow: 1;
  overflow: hidden;
}

.dragBottom {
  display: flex;
  justify-content: center;
  gap: calc((32vmin / 900) * 100);
  padding: calc((16vmin / 900) * 100) calc((24vmin / 900) * 100);
}

@media screen and (max-height: 560px) {
  .dragModal {
    min-width: 640px;
    border-radius: 16px;
  }
  .draglHead {
    padding: 8px 12px;
  }
  .dragTitle {
    font-size: 18px;
  }
  .dragCloseButton {
    width: 20px;
    height: 20px;
  }
  .dragBottom {
    gap: 32px;
    padding: 8px 12px 12px;
  }
}
