.cardTitle {
    padding: 10px 0;
    color: navy;
    font-size: 8vmin;
    font-weight: bold;
    text-align: center;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    .button {
        background-color: steelblue;
        padding: 5px 10px;
        min-width: 140px;
        font-size: 7vmin;
        &:active,
        &:focus {
            background-color: steelblue;
            color: #fff;
        }
    }
}

.controlBar {
    position: relative;
    display: flex;
    @media only screen and (min-width: 901px) {
        justify-content: center;
    }

    @media only screen and (max-width: 900px) {
        justify-content: space-between;
    }
    width: 100%;
    min-height: 70px;
    .controlsButtonGroup {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 20px;
        svg {
            width: 75%;
            height: 75%;
        }
        svg path {
            fill: #fff;
        }
        .handpaper {
            width: 60%;
            &.rotate {
                transform: rotateY(180deg);
            }
        }
        .controlsButton {
            transition: 0.3s;
            &:hover {
                transform: scale(1.2);
                transition: 0.3s;
            }
            &.hide {
                display: none;
            }
        }
        .controlsButton + .controlsButton {
            margin-left: 10px;
        }
        &.middle {
            background-color: rgba(255, 255, 255, 0.85);
        }
        &.right {
            position: absolute;
            bottom: 0;
            right: 5px;
            background-color: rgba(255, 255, 255, 0.85);
            opacity: 0;
            &.show {
                opacity: 1;
            }
            svg {
                width: 50%;
                height: 50%;
            }
        }
    }
    .controlBarLabel {
        color: steelblue;
        padding: 0 10px;
        font-weight: bold;
        font-size: 30px;
    }
    .speedControls {
        font-size: 40px;
    }
}

.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 95%;
    height: 85%;
}

.flashCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    background-image: url('/assets/img/flashcardBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 10vmin;
    .hoverCard {
        margin: 2% 0;
        width: 100vmin;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
            transition: 0.3s;
            .cardTitle {
                color: #f3a002;
            }
        }
    }

    .stepSecondSection {
        margin: 15px 0;
        width: 120vmin;
        .button {
            &:hover {
                background-color: #f3a002;
            }
        }
    }

    .stepThirdSection {
        width: 100%;
        height: 100%;
        position: relative;
        .flipper {
            position: relative;
            transform-style: preserve-3d;
            perspective: 800px;
            transition: 0.5s;
            width: 100%;
            height: 100%;
            &.rotate {
                transform: rotateY(180deg);
            }
            .side {
                position: absolute;
                display: flex;
                align-items: center;
                bottom: 0;
                left: 0;
                backface-visibility: hidden;
                width: 100%;
                height: 95%;
                transition: 0.5s;
                .decoIcon {
                    position: absolute;
                    top: -9%;
                    text-align: center;
                    z-index: 100;
                    img {
                        width: 50%;
                    }
                }
                &.front {
                    z-index: 2;
                    transform: rotateY(0deg);
                    opacity: 1;
                    &.hide {
                        opacity: 0;
                    }
                    .decoIcon {
                        right: 50px;
                    }
                }
                &.back {
                    z-index: 3;
                    transform: rotateY(180deg);
                    .decoIcon {
                        left: 50px;
                    }
                }
            }

            .image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                transition: 0.2s;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
                .word{
                  color: #000;
                  font-size: 26vmin;
                  font-family: Calibri, arial;
                }
            }

            .content {
                width: 100%;
                height: 100%;
                overflow-y: scroll;
                .example {
                    width: 90%;
                    margin: 0 auto;
                    .word {
                        display: flex;
                        align-items: center;
                        .voca {
                            padding-left: 15px;
                        }
                    }
                    .info {
                        display: flex;
                        align-items: center;
                        .KK {
                            padding-left: 15px;
                            padding-right: 40px;
                            // margin: 0;
                            ~ div {
                                color: steelblue;
                                font-size: 1em;
                                // @media (max-width: 700px){
                                //     font-size: 1em;
                                // }
                            }
                        }
                    }
                    .exampleSentence {
                        display: flex;
                        .exampleSentenceContent {
                            padding-left: 15px;
                            p {
                                line-height: 1.2em;
                            }
                        }
                    }
                    .tw {
                        font-size: 1em;
                        text-align: left;
                    }
                }
                .vocabulary {
                    position: relative;
                    font-size: 1.5em;
                    text-align: center;
                    .audioButton {
                        position: absolute;
                        top: -100%;
                        left: 0;
                        opacity: 0;
                    }
                    .KK {
                        font-size: 0.5em;
                        // @media (max-width: 700px) {
                        //     font-size: 0.5em;
                        // }
                    }
                    .tw {
                        font-size: 0.75em;
                    }
                }
                .word {
                    font-size: 1.2em;
                    padding-top: 5%;
                    text-align: center;
                    color: steelblue;
                    // @media (max-width: 700px) {
                    //     padding-top: 5%;
                    //     font-size: 1.2em;
                    // }
                }

                .KK {
                    font-size: .8em;
                    // @media (max-width: 700px) {
                    //     font-size: .8em;
                    // }
                }
                .tw {
                    color: steelblue;
                    opacity: 0;
                    &.show {
                        opacity: 1;
                    }
                }
                .translationButton {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    user-select: none;
                    z-index: 1000;
                }
            }
        }
    }

    svg path {
        fill: #fff;
    }
    p {
        margin: 0;
    }
}

.controlsButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    min-width: 60px;
    min-height: 60px;
    font-size: 18px;
    font-weight: bold;
    user-select: none;
}
