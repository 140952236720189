.submenuContent {
    //position: absolute;
    //width: 100%;
    //height: 100%;
}

.items{
    position: absolute;
    top:200px;
    left:200px;
    
}

.itemul{
    padding: 0px;
    margin: 0px;
    background-color: #51464C;
    min-width: 70px;
}
.itemli{
    list-style-type:none;
}
.btn{
    color: azure;
}
.edit{
    background-color: #91DEBE;
    color: #000;
    padding: 5px;
    width: 65%;
    max-width: 45px;
    margin: 0 0 0 auto;
    text-align: center;
    cursor: pointer;
}