.inputArea {
	position: relative;
	width: 100%;
	.inputBtns {
		position: absolute;
		right: 0;
		bottom: -7px;
		button {
			min-width: initial;
			width: 2.5rem;
			height: 1.8rem;
			padding: 8px;
			background: #FAFAFC;
			border-radius: 8px;
			white-space: nowrap;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		}
		button:nth-child(2) {
			margin-left: 4px;
		}
		span {
			font-size: 11px;
			color: #454B5C;
		}
	}
	span {
		display: block;
		color: rgba(0, 0, 0, 0.54);
		font-size: 0.875rem;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		font-weight: 400;
		line-height: 1.43;
		letter-spacing: 0.01071em;
		margin-top: 4px;
	}
}