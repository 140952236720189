
.stopwatch{
  position: relative;
  width: 100%;
  height: 100%;
  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}
