.colorSelector {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  .color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #fff;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    .icon {
      display: none;
      color: #fff;
    }

    &.active {
      font-size: 15px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
    }

    &.customizedColor {
      position: relative;
      overflow: hidden;
      background: linear-gradient(to bottom, #ff0000 14%, #ffa830 28%, #f4ff28 42%, #28ff2f 56%, #005dff 70%, #7200ff 84%, #ff00cb 100%);

      label {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      input {
        position: absolute;
        display: none;
      }
    }

    &.transparent {
      display: flex;
      align-items: center;
      border: 0px;
      font-size: 30px;
      color: #fff;
    }
  }
}