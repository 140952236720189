.onlineDialog {
  width: 500px;
  height: 160px;

  background: #ffffff;
  border-radius: 20px;
  padding: 0 20px 20px 20px;
  .title {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    line-height: 36px;
    color: #121232;
    padding-bottom: 10px;
  }
  .content {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 28px;
    color: #121232;
  }
}
.onlineDialogBtn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 48px;
  margin: 12px 16px;

  background: #000000;
  border-radius: 8px;;
  color: white;
}