.extended_content_tools {
    position: relative;
    width: 100%;
    .toolsWrapper{
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      button {
        color:#414141;
        img{
            width: 50%;
        }
    }
    }

    .toolbox {
        display: flex;
        align-items: center;
        width: 90%;
    }
    .hideToolbox {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .rightbox{
      display: flex;
      align-items: center;
    }

    .toolBtn{
        position: relative;
        .painterPanel{
            left: 0;
            transform: initial;
        }
    }
}
