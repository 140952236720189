@import '../../../style/media';

.popover {
  position: absolute;
  //width: 70vmin;
  //min-width: 300px;
  padding: 5px 20px 10px 20px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  font-size: 3vmin !important;

  @include phone {
    width: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fff;
    cursor: pointer;
  }

  &.top {
    top: 110%;
    left: 50%;
    bottom: initial;
    right: initial;
    transform: translateX(-50%);
  }

  &.bottom {
    bottom: 110%;
    left: 50%;
    top: initial;
    right: initial;
    transform: translateX(-50%);
  }
  @media screen and (orientation: landscape) and (max-height: 375px) {
    &.bottom {
        width: 85%; /* 在横向布局且高度小于360px时将宽度设为70% */
    }
  }

  &.left {
    top: 50%;
    left: 110%;
    bottom: initial;
    right: initial;
    transform: translateY(-50%);
  }

  &.right {
    top: 50%;
    right: 110%;
    bottom: initial;
    left: initial;
    transform: translateY(-50%);
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.toolTitle {
  padding: 10px 0;
}

.penPanelBtn {
  border-radius: 50%;
  min-width: 4vmin;
  width: 4vmin;
  height: 4vmin;
  margin: 0 5px;
  padding: 5px;
  background-color: #fff;

  &.active{
    background-color: #bbb;
  }
}

.expression{
  display: flex;
  &.expressionBtn{
    width: 15%;
    color: white;
    margin: 5px;
  }
}

.formGroupItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  .formGroupItem {
    flex: 1;
  }
}

.group{
  display: flex;
  justify-content: space-between;
}
