.markerWithSound {
  width: 100%;
  height: 90% !important;
  overflow-y: auto;
  background-color: rgb(255, 196, 0);
  text-align: center;
  font-family: Arial, Microsoft JhengHei, 微軟正黑體, -apple-system,
    BlinkMacSystemFont;
  // position: relative;

  .markerWithSoundPlayBar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .track {
    padding: 10px 0;
    color: #000;
    font-size: 8vmin;
    position: relative;
    cursor: pointer;

    span {
      display: block;
      position: relative;
      padding-left: 72px;
      width: 100%;
      &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 64px;
        height: 64px;
      }
    }

    &.show {
      color: #ffffff;
      background-color: #000;
      //font-size: 60px;
      font-weight: bold;
      span {
        &:after {
          background-image: url('/assets/icon/audio/bee.svg');
        }
      }
    }
  }
}
