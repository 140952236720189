.partialButton{
  display: flex;
  padding: 10px 0;
}
.button {
  height: 40px;
  margin: 0 5px;
  padding: 5px 10px;
  color: #fff;
  background-color: #6dd1cf;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  word-break: keep-all;

  &:hover {
    color: #999;
    background-color: #99ffff;
  }
  &.active {
    color: #999;
    background-color: #99ffff;
  }
  &.disabled {
    color: #999;
    background-color: #ccc;
    pointer-events: none;
  }
}
