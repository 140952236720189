.questionPanel{
  position: absolute;
  top:0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px 30px;
  background-color:rgba(0,0,0,0.8);
  z-index: -1;
  opacity: 0;
  &.showing{
    z-index: 1006;
    opacity: 1;
  }

  .titleWrapper{
    .title{
      padding: 20px;
      border: 4px solid steelblue;
      border-radius: 10px;
      background-color: rgb(236,230,223);
      font-weight: bold;
      font-size: 8vmin;
    }
  }

  .answersWrapper{
    height: 100%;
    overflow: auto;
    .answers{
      padding: 20px;
      font-size: 7.5vmin;
      .options{
        position: relative;
        display: flex;
        align-items: center;
        margin: 20px 0;
        padding: 10px 15px 10px 30px;
        font-weight: bold;
        border: 4px solid rgb(230,166,116);
        border-radius: 10px;
        background-color: rgb(236,230,223);
        transform: scale(1);
        transition: .5s;
        cursor: pointer;
        &:hover{
          transform: scale(1.03);
          transition: .5s;
        }
      }

      .choice{

        .icon{
          display: flex;
          width: 40px;
          height: 100%;
          margin-right: 30px;
          opacity: 0;
        }
        .options{
          &.active .icon{
            opacity: 1;
          }
          &.correct .icon{
            color: rgb(70,184,103);
          }
          &.wrong .icon{
            color: rgb(234,35,47);
          }
        }
      }

      .TFTest{
        display: flex;
        .options{
          flex:1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 10vmin;
          + .options{
            margin-left: 40px;
          }
          &.active{
            &.correct{
              background-color: rgb(70,184,103);
            }
            &.wrong{
              background-color: rgb(234,35,47);
            }
          }
        }
      }
    }
  }

  .footer{
    padding: 10px 0px;
    text-align: center;
    .goOnButton{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      font-size: 24px;
      color: #fff;
      background-color: steelblue;
      border-radius: 10px;
      transition: .5s;
      transform: scale(1);
      cursor: pointer;
      &:hover{
        transform: scale(1.1);
        transition: .5s;
      }
    }
    .goOnButton + .goOnButton{
      margin-left: 30px;
    }
  }
}


.audioButton{
  display: none;
}
