.stampPanel {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around
    > button {
        width: 30%;
        min-width: auto;
        > img {
            width: auto;
            max-width: 100%;
        }
    }
}