.hearingDraft {
  &.large {
    font-size: 10vmin;
  }
  &.medium {
    font-size: 8vmin;
  }
  &.small {
    font-size: 6vmin;
  }

  .main{
    display: flex;
    height: 92%;

    .icon{
      width: 5%;
      font-size: 0;
      
      img{
        width: 100%;
        cursor: pointer;
      }
    }

    .topic{
      width: 95%;
      padding-left: 10px;
      overflow-y: auto;

      .title{
        width: 100%;
        margin-bottom: 10px;

        img{
          max-width: 100%;
          width: auto;
        }
      }
      .content{
        line-height: 1.3;
        .list{
          margin: 10px 0;
        }
      }
    }
  }

  .tab{
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
    .list{
      width: 5vmin;
      height: 5vmin;
      font-size: 3vmin;
      display: flex;
      justify-content: center;
      align-items: center; 
      border-radius: 50%;
      color: #fff;
      background-color: #79CDBF;
      margin: 0 10px;
      cursor: pointer;

      &.active{
        background-color: #F49499;
        cursor: default;
      }
    }
  }
}
