.settingPanel {
  width: 100%;
  color: #fff;
  background-color: rgba(66,66,66, 0.9);
  height: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  padding: 0px;
  &.active {
    padding: 10px 15px;
    height: auto;
  }
  .controlSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    .label{
      width: 40%;
    }
    &.checkBoxSection{
      justify-content: flex-end;
    }
  }
  .selectGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 5px 0;
    .defaultWording{
      text-align: center;
      width: 100%;
      background-color: #aaa;
      color: #fff;
      border-color: rgb(166, 166, 166);
      border-radius: 5px;
      border-width: 1px;
      font-size: 16px;
      padding: 6px 10px;
      font-weight: bold;
      cursor: pointer;
    }
    .select{
      width: 100%;
    }
  }
}
