
.noteActivitySelect {
	background-color: "#f50057";
	&.loading {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.editor{
	padding-left: 5px;
	color: blue
}
.editor span{
	padding-left: 5px;
}

.editorIcon{
	padding-right: 5px;
	color: blue
}

.tip {
	margin-top: 5px;
	color: #25a53f;
}

