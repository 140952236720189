.pageSearcher {
  display: flex;
  margin-bottom: 2px;
  height: 6vmin;
  cursor: pointer;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  color: #fff;
  background-color: #ff7e00;

  &.prev {
    border-radius: 5px 0 0 5px;
    margin-right: 3px;
  }

  &.next {
    border-radius: 0 5px 5px 0;
    margin-left: 3px;
  }
}

.pageInfo {
  text-align: center;
  box-sizing: border-box;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break:keep-all;
  overflow: hidden;
  padding: 5px;
  font-size: 1.86vmin;
  text-overflow: ellipsis;
  cursor: pointer;
  border: 2px solid #ff7e00;
  border-radius: 6px;
  background-color: #fff;
}

.text {
  text-align: center;
  color: #fff;
  font-size: 0.83vmax;
}