$desktop-media:1025px;
$phone-media:600px;

@mixin desktop() {
    @media screen and (min-width: $desktop-media) {
        @content;
    }
}

@mixin tablet() {
    @media screen and (max-width: $desktop-media - 1) and (min-width: $phone-media + 1){
        @content;
    }
}

@mixin phone() {
    @media screen and (max-width: $phone-media) {
        @content;
    }
}


// how to use

// 1.
// import this file into component's index.module.scss

// 2.
// @include phone{
//     padding:20px;
// }
