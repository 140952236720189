.lockTools {
  .lockToolsInput {
    display: flex;
  }
  .checkbox {
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 0 0 30px 0;
    cursor: pointer;
    label {
      text-align: center;
      cursor: pointer;
    }
  }
}
