.svgContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 11;

  &.hide {
    opacity: 0;
  }

  g,
  image {
    pointer-events: none;
    cursor: pointer;
  }

  .gOpacity {
    opacity: 1 !important;
  }

  & > svg {
    display: block;

    .objectHover {
      opacity: 0.3 !important;
      mix-blend-mode: multiply !important;
    }

    .studentRead {
      opacity: 0.5 !important;
      mix-blend-mode: multiply !important;
      fill: rgb(131, 225, 107) !important;
    }
  }

  &.down {
    z-index: 0;
  }

  .clickable {
    pointer-events: auto !important;
    -webkit-user-select:none;
    -moz-user-select:none;
    -o-user-select:none;
    user-select:none;

    * {
      pointer-events: auto !important;
    }
    &.largerClickArea * {
      pointer-events: fill !important;
    }
  }
}

.canvasObjectSavedSvgContentEnable {
  pointer-events: none;
}

.canvasObjectSavedSvgContentDisable {
  pointer-events: none;
}
