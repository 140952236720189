.swipeContainer {
  width: 100%;
  height: 100%;
}

.pinchPanContainer {
  width: 100%;
  height: 100%;
  user-select: auto;
  & > div {
    width: 100%;
    height: 100%;
    user-select: auto;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
}
