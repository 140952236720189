@import "variables";

.bg {
  position: relative;
  background-color: $bg-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  font-weight: 700;
  height: 100%;
  color: $main-dark;
  overflow-x: hidden;
  @media screen and (max-height: 560px) {
    align-items: flex-start;
  }
}

.createCard {
  display: flex;
  flex-direction: column;
  width: 49%;
  max-height: 90%;
  border-radius: 0.5em;
  border: 2px solid #dfe3e8;

  @media (max-width: 1200px) {
    width: 70%;
  }

  .cardHead {
    background: $main-dark;
    padding: 0.25em 1.25em;
    border-radius: 0.5em 0.5em 0em 0em;
    color: white;
    text-align: center;
  }

  .cardContent {
    padding: 0.75em 1.5em;
    background: white;
    border-radius: 0em 0em 0.5em 0.5em;
    flex: 1 0 0;
    overflow: auto;
  }

  .inputGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;

    .text {
      text-align: left;

      span {
        display: block;
      }

      span:nth-of-type(2) {
        font-size: 0.5em;
      }
    }

    .input {
      width: 45%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div {
        width: 100%;
      }
    }

    &.hide {
      opacity: 0;
    }
  }

  .dashedDivider {
    display: block;
    height: 1px;
    width: 100%;
    border-style: dashed;
    border-width: 0px 0px 1px 0px;
    border-color: #919eab;
    margin-bottom: 0.75em;
  }

  .actionBtn {
    display: flex;
    margin-top: 1em;
    justify-content: space-between;
    align-items: center;
  }
}

.waitSection {
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: space-between;

  .qrCode {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    height: 100%;
    background: white;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    text-align: center;

    .instruction {
      color: #b7601a;
    }

    .discription {
      color: $main-dark;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 52%;

    .individual {
      display: flex;
      flex-direction: column;
      height: 85%;

      .individualHead {
        color: white;
        background: $main-dark;
        border-radius: 0.5em 0.5em 0em 0em;
        padding: 0.1em;
        text-align: center;
      }

      .individualContent {
        flex: 1 0 0;
        font-size: 0.7em;
        background: white;
        border-radius: 0em 0em 0.5em 0.5em;
        padding: 0.1em 0.7em;
        overflow-y: auto;
      }

      .listItem {
        display: flex;
        justify-content: space-between;

        .status {
          flex: 0 0 auto;
          &.warn {
            color: #D32613;
          }
        }
      }
    }
  }

  .total {
    font-size: 0.7em;

    .icon {
      margin-right: 20px;
    }
  }
}

.resultHead {
  font-size: 0.7em;
  color: #212b36;
  margin-bottom: 0.3em;

  .headerIcon {
    color: #ffa235;
    margin-right: 0.2em;
  }
}

.resultCard {
  border-radius: 0.5em;
  background: white;
  margin: 0.5em 0em;
  width: 93%;
  padding: 0.7em;
  border: 2px solid #dfe3e8;

  .resultContent {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    min-height: 320px;

    .group {
      flex: 1 0 0;
      margin-right: 0.3em;
      display: flex;
      flex-direction: column;
      // min-width: 180px;

      &:nth-last-child(1) {
        margin-right: 0em;
      }

      .groupHead {
        color: white;
        background: $main-dark;
        text-align: center;
        border-radius: 0.5em 0.5em 0em 0em;
        border-bottom: 4px solid white;
        padding: 0.2em;
        font-size: 0.6em;
        font-weight: 700;
      }

      .students {
        display: flex;
        justify-content: space-between;
        // flex: 1 0 0;
        height: 320px;
        font-size: 60%;
        border-radius: 0em 0em .5em .5em;
        background: #fff3d6;
        // font-weight: 500;
        overflow-y: scroll;

        .unit {
          flex: 1 0 0;
          border-right: 4px solid white;
          display: flex;
          flex-direction: column;
          row-gap: 0.2em;
          min-width: 180px;
          // &:nth-child(1) {
          //   border-radius: 0em 0em 0em 0.5em;
          // }

          &:nth-last-child(1) {
            border-right: 0px;
            // border-radius: 0em 0em 0.5em 0em;
          }
        }

        .name {
          display: flex;
          justify-content: space-between;
          padding: 0 9px;
          line-height: 4vh;

          &.selected {
            background: #FFA2357A;
          }

          .text {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            // flex: 1 1 6em;
            max-width: 78%;
          }

          .status {
            color: #637381;
            font-weight: 700;
            white-space: nowrap;
            svg {
              width: 1em;
              height: 1em;
            }
            &.warn {
              color: #D32613;
            }
          }
        }
      }
    }
  }

  .description {
    text-align: center;
    font-size: 0.9em;
    color: #212b36;
    margin-bottom: 0.5em;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1em;
      max-height: 50px;
    }

    span {
      color: #ffa235;
    }

    svg {
      width: .9em;
      height: .9em;
      margin-left: .3em;
    }
  }

  .actionBtn {
    display: flex;
    justify-content: center;
    column-gap: 0.8em;
  }
}

.previewHandWrite {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: .6em;

  .picContainer {
    flex: 1 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
    height: 95%;
    // background: white;
    .image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // aspect-ratio: 900 / 488;
      background: white;
      // min-width: 450px;
      outline: 5px solid #315D83;
      text-align: center;
      width: var(--picWidth);
      height: var(--picHeight);
      // height: calc(var(--picWidth) * 488 / 900);
    }

    .name {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: #315D83;
      color: white;
      font-size: 50%;
      padding: 2px 8px;
      border-radius: 0px 0px 8px 8px;
      white-space: nowrap;
    }

    img {
      height: 100%;
      max-width: 100%;
      // object-fit: contain;
      // flex: 1 0 450px;
    }
  }
}

.historyBtn {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: .6em;
  color: white;
  background: #D32613;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  border-bottom: 5px solid #660114;
  line-height: 1em;
  padding: .5em;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: #660114;
  }

  &.isDisabled {
    pointer-events: none;
    filter: grayscale(80%);
  }
}

.copyLinkArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #88BCD9;
  padding: .1em .1em;
  background: white;
  width: 100%;
  border-radius: 8px;
  margin: .1em 0em;
  .link {
    width: 80%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .7em;
    user-select: all;
    cursor: text;
  }
}