.red{
  color:#ed1c24;
}
.brown{
  color:#900;
}
.purple{
  color:#60c;
}
.blue{
  color:#039;
}
