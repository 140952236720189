// 大中小
.small .aboutTheAuthor {
  .article {
    // font-size: 6vmin;
  }
}

.medium .aboutTheAuthor {
  .article {
    // font-size: 8vmin;
  }
}

.large .aboutTheAuthor {
  .article {
    // font-size: 10vmin;
  }
}

.aboutTheAuthor {
  height: 100%;
  transform-style: preserve-3d;

  .article {
    line-height: 2.5;
    height: 100%;
    // font-size: 7vmin;
    font-family: cursive; // 標楷體
    text-align: justify;

    &.chineseOnly { // 高年級無注音
      line-height: 1.7;
    }
    
    // 字型
    ruby {
      // margin: 1vmin 0;
      // display: inline;

      rt {
        font-family: BopomofoRuby; // 注音
      }
    }

    // 標點符號
    sign {
      font-family: cursive;
      text-indent: initial;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    /* 段落縮排 */
    .indent {
      text-indent: 2em;
    }

    /* 私名號 直線 */
    .private_name {
      border-left: .5vmin solid transparent;
      border-image: url(line.png) 30 10 repeat;

      /* 換紅色 */
      &.red {
        border-image: url(redLine.png);
      }

      /* 換粉紅色 */
      &.pink {
        border-image: url(pinkLine.png);
      }

      /* 換藍色 */
      &.pink {
        border-image: url(blueLine.png);
      }
    }

    /* 私名號 波浪 */
    .flow_wave {
      border-left: 1vmin solid transparent;
      border-image: url(wave.png) 30 20 round;

      /* 換紅色 */
      &.red {
        border-image: url(redWave.png);
      }

      /* 換粉紅色 */
      &.pink {
        border-image: url(pinkWave.png);
      }

      /* 換藍色 */
      &.pink {
        border-image: url(blueWave.png);
      }
    }
  }
}
