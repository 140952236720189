@import '../../../style/media';

.label {
  padding-right: 10px;
  cursor: pointer;
}

.buttonGroup {
  display: flex;
  flex-wrap: nowrap;
  padding: 3px;

  .item {
    width: 100%;
    padding: 2px 5px;
    text-align: center;
    font-size: initial;

    @media screen and (max-width: 1366px) and (min-width: 1281px) {
      width: 50%;
    }

    button {
      display: inline-block;
      width: 4vmin;
      height: auto;
      appearance: none;
      color: #323232;
      background-color: #fff;
      font-size: 2.2vmin;
      font-weight: bold;
      font-family: '微軟正黑體';
      border-radius: 15px;
      border: 0;
      cursor: pointer;

     
    }

    &.active button,
    &:hover button {
      background-color: #cdffce;
    }

    &.disabled button {
      color: #8f8f8f;
      background-color: #d9d9d9;
      cursor: not-allowed;
    }
  }
}
