
@keyframes loading {
  0 {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.load {
  margin: 50px;
  .ring {
      width: 10px;
      height: 10px;
      margin: 0 auto;
      padding: 10px;
      border: 3px dashed #FF6700;
      border-radius: 100%;
      animation: loading 1.5s linear infinite;
  }  
}
