.formText {
  display: none;
  color: black;
}

.list {
  margin: 0.5rem 0rem;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #e6e6e6;
}

.dialogContent {
  width: 500px;
}
