.canvasWrapper{
    display: none;
    position: absolute;
    top:0;
    left: 0;
    z-index: 1002;
    &.show{
        display: block;
    }
}
