.syncContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  height: 100%;
  display: none;
  pointer-events: none;
  &.show {
    display: block;
    pointer-events: auto;
  }
  .syncBG {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
  }
  .iframeContent {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    .iframeArea {
      width: 90%;
      height: 90%;
      display: inline-block;
      vertical-align: middle;
      transform: translate(0%, 5%);
      border-radius: 20px;
      .iframe {
        border: 0;
      }
    }
  }

  .closeBtn {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    & > div {
      margin: 10%;
      & > button {
        position: absolute;
        bottom: 5%;
        right: 5%;
        pointer-events: auto;
      }
    }
  }
}
