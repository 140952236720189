.zhuyinLesson {
  display: flex;
  flex-flow: row-reverse;

  .options {
    margin: 0 1rem;

    .audio {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }

    .title {
      display: flex;
      flex-wrap: wrap;
      max-height: 73vmin;
      writing-mode: vertical-rl;
      border-radius: 5px;
      color: #fff;
      background-color: #0064cf;
      line-height: 1;

      & > div {
        writing-mode: vertical-rl;
        margin: 0;
      }
    }
  }

  .lesson {
    direction: ltr;
    writing-mode: vertical-rl;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}