@import "../variables";

.bg {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: $bg-color;
	// min-height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left bottom;
	font-size: 2em;
	overflow-y: auto;
	padding: .5em;

	&.centered{
		justify-content: center;
	}

	.headText {
		color: #1890ff;
		font-weight: 700;
		margin-bottom: .75em;
	}
}

.inputCard {
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: .5em;
	border: 2px solid #DFE3E8;
	padding: 1em 1.5em;
	width: 50%;

	@media (max-width: 900px) {
		width: 90%
	}

	@media (max-width: 450px) {
		padding: .5em .8em;
	}


	.actionBtn {
		width: 50%;
		align-self: center;
		margin-top: .25em;
		padding: .5em 0em;
	}
}

.answerSection {
	width: 90%;

	@media (max-width: 450px) {
		width: 95%;
	}
}

.answerHeader {
	display: flex;
	justify-content: space-between;
	// width: 85%;
	font-size: .6em;
	font-weight: 700;
	color: #18365E;

	svg {
		color: #88BCD9;
		margin-right: .5em;
	}

	.total {
		font-size: .9em;
		color: #637381;
	}
}

.answerActionBtn {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: space-around;
	width: 35%;
	gap: 1em;

	@media (max-width: 850px) {
		width: 55%;
	}

	@media (max-width: 450px) {
		width: 85%;
	}

	.btn {
		flex: 1 0 0;
	}
}

@keyframes shake {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(5px, 5px) rotate(5deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  75% { transform: translate(-5px, 5px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

.optionArea {
	margin-top: .6em;
	padding-top: .6em;
	padding-bottom: 3em;
	// width: 85%;

	.quizNo {
		font-size: 1.2em;
		font-weight: 700;
		text-align: center;
		margin-bottom: .8em;
	}

	.option {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		row-gap: .4em;
		// min-height: 362px;

		.btn {
			width: 48%;
			height: 2.7em;
			
			&.shake {
				animation: shake .2s linear 1;
			}

		}
	}

	@media (max-width: 450px) {
		margin-top: .2em;
		padding-top: .2em;
		
		.quizNo {
			margin-bottom: .2em;
		}

		.option {
			flex-direction: column;
			justify-content: center;
			row-gap: .2em;

			.btn {
				width: 100%;
				height: 1.8em
			}
		}
	}
}

.submitSection {
	width: 85%;
	text-align: center;

	.mainText {
		display: block;
		font-size: .8em;
		font-weight: 700;
		margin-bottom: 4px;
	}

	.subText {
		display: block;
		font-size: .6em;
		font-weight: 700;
		color: #637381;
	}

	.btn {
		width: 20%;
		margin: 6% auto 0;

		@media (max-width: 450px) {
			width: 35%;
		}
	}
}

.canvasArea {
	position: relative;

	&.shake {
		animation: shake .2s linear 1;
	}
}

.canvasToolbar {
	position: absolute;
	right: 11px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 40px;
	height: 188px;
	background: #F4F6F8;
	border: 1px solid #DFE3E8;
	border-radius: 20px;
	padding: 8px 0px;

	button {
		padding: 0;
		width: 40px;
		height: 40px;

		&.active {
			color: #1890FF;
		}
	}
}