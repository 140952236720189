/* 私名號 直線 */
.private_name {
  border-left: .5vmin solid transparent;
  border-image: url(line.png) 30 10 repeat;

  &.red{
    border-image: url(redLine.png) 30 10 repeat;
  }

  &.pink{
    border-image: url(pinkLine.png) 30 10 repeat;
  }

  &.blue{
    border-image: url(blueLine.png) 30 10 repeat;
  }
}

/* 私名號 波浪 */
.flow_wave {
  border-left: 3vmin solid transparent;
  border-image: url(wave.png) 30 30 round;

  &.red{
    border-image: url(redWave.png) 30 30 repeat;
  }

  &.pink{
    border-image: url(pinkWave.png) 30 30 repeat;
  }

  &.blue{
    border-image: url(blueWave.png) 30 30 repeat;
  }
}