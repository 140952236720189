.inputWrapper{
    margin: 10px 0;
    label{
        div{
            padding-bottom: 5px;
        }
        input{
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ccc;
        }
    }
}