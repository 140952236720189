.sidePanel{
  position: fixed;
  bottom: 100px;
  width: 40vmin;
  min-width: 200px;
  min-height: 200px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 5px;
  padding: 5px 10px;
  z-index: 10000;
  &.left {
    left: 40px;
  }

  &.right{
    right: 40px;
  }
  .closeBtn {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fff;
    cursor: pointer;
  }
}