.card {
  position: relative;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 25px;
  .cardContent {
    position: relative;
    padding: 10px;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
}
