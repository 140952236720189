.dropdown {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  max-width: 150px;
  width: 100%;
  height: 25px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  margin: 10px 0;

  .value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 25px);
    height: 100%;

    .menuItem {
      width: 100%;
    }
  }

  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 100%;
    background-color: #fff;
    color: #000;
  }

  .menu {
    position: absolute;
    top: 130px;
    left: 0;
    border: 1px solid #ccc;
    width: 100%;
    transform: translateY(-50%);
    max-height: 300px;
    overflow-x: hidden;
    z-index: 1;

    .menuItem {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      width: 100%;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: #007bff;
        color: #fff;
      }
    }
  }
}

