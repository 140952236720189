.originalContent {
  display: flex;
  justify-content: space-between;
  gap: 3.75%;
  padding: 5% 3.75%;

  .immediatePai,
  .oneLinkPai {
    flex-grow: 1;
    background-color: #F4F6F8;
    border-radius: 1.25%;
    text-align: center;
    padding: 6.3% 5%;
    position: relative;
    border: 4px solid #C4CDD5;
    font-size: 24px;
    cursor: pointer;

    &.choose {
      border: 4px solid #FFA235;
      background-color: #FFF3D6;
    }

    h2 {
      display: inline-block;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;
      color: #7A310A;
      margin: 0 0 8px;
    }

    p {
      font-size: 16px;
      font-weight: 500px;
      line-height: 1.5;
      color: #637381;
      margin: 0;
    }

  }

  @media screen and (min-width: 1440px) {
    .immediatePai,
    .oneLinkPai {
      font-size: 1.667vw;
      h2 {
        font-size: 1.667vw;
      }
      p {
        font-size: 1.111vw;
      }
    }
  }

  @media screen and (max-height: 300px) {
    padding: 1% 1%;
    .immediatePai,
    .oneLinkPai {
      padding: 1% 1%;
    }
  }
  // .immediatePai {
  //   margin-right: 24px;
  // }
}

%bottomWhiteButton {
  border: none;
  background-color: #F4F6F8;
  aspect-ratio: 1 / 0.28;

  img {
    vertical-align: text-bottom;
    margin-left: 12px;
  }

  @media screen and (max-height: 560px) {
    aspect-ratio: 1 / 0.24;
  }
}

.originalBottom {
  width: 32.895%;
  button {
    color: #7A310A;
    background: #FFA235;
    border-bottom: 5px solid #B7601A;
    @extend %bottomWhiteButton
  }
}

.noAccessBottom {
  width: 32.895%;
  button {
    color: #454F5B;
    background: #C4CDD5;
    border-bottom: 5px solid #637381;
    @extend %bottomWhiteButton
  }
}

.immediateContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.75%;
  padding: 3.75%;
  // height: 100%;
  color: white;

    .qrTextBlock {
      flex-grow: 1;
      width: 50%;
    }

    .qrTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      color: #212B36;
    }
    
    .qrText {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: #637381;
      padding-top: 0.8em;
      border-top: 0.05em dashed #919EAB;
    }

    .qrCode {
      width: 50%;
      background: white;
      padding: 12px;
      border: 1px solid #D5D7DE;
      border-radius: 8px;
      height: auto; /* 保持宽高比例 */

      svg {
        width: 100%;
        height: auto;
      }
    }

  .linkArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    margin: 0.308em 0 1.231em;
    font-size: 13px;
    color: #315D83;
    border: 2px solid #88BCD9;
    border-radius: 8px;
    background-color: #F4F6F8;

    .linkText {
      width: 12.538em;
      flex-grow: 1;
    }

    .mobile {
      display: none;
    }

    span {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.231em;
      font-weight: 500;
      line-height: 1.5;
    }

    button {
      padding: 0.308em 0.769em;
      width: auto;
      min-width: 98px;
      height: initial;
      font-size: 13px;
      font-weight: 700;
      line-height: 1.692;
      color: #315D83;
      appearance: none;
      border: none;
      border-radius: 6px;
      background-color: #DFE3E8;
      white-space:nowrap;
      cursor: pointer;

      img {
        margin-right: 6px;
        vertical-align: middle;
      }
    }
  }

  @media screen and (max-height: 560px) {
    padding: 3% 5%;

    .qrCode {
      width: auto;
      min-width: 200px;
    }
    .linkArea {
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .qrTitle {
      font-size: 1.667vw;
    }
    .linkArea {
      font-size: 0.902vw;
      button {
        font-size: 0.902vw;
      }
    }
    .qrText {
      font-size: 1.389vw;
    }
  }
}

.immediateBottom {
  text-align: center;
  width: 32.895%;

  .resultLink {
    display: inline-block;
    padding: 5% 8%;
    width: 100%;
    appearance: none;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    color: #7A310A;
    background: #FFA235;
    border: 0;
    border-bottom: 5px solid #B7601A;
    border-radius: 12px;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    &.forElectron {
      padding: 0;
    }
  }

  @media screen and (max-height: 560px) {
    .resultLink {
      font-size: 18px;
      line-height: 1.556;
    }
  }

  @media screen and (min-width: 1440px) {
    .resultLink {
      font-size: 1.667vw;
    }
  }
}

.oneLinkContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 40px 0px;
  min-width: 640px;
  height: 338px;
  overflow-y: auto;
  position: relative;

  &.freeze {
    overflow: hidden;
  }

  .class {
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
    width: 47%;
    margin-bottom: 32px;
    cursor: pointer;

    img {
      height: 70px;
      margin-right: 11px;
    }

    p {
      margin: 0;
    }
  }
}

.oneLinkBottom {
  text-align: center;

  button {
    width: 220px;
    padding: 11px 70px;
    border: none;
    appearance: none;
    border-radius: 8px;
    background: #EC7963;

    &.disable {
      cursor: not-allowed !important;
      user-select: none;
      color: #A1A4B1;
      background: #D5D7DE;
    }
  }
}

.chooseQuizContent {
  display: flex;
  justify-content: space-between;
  gap: 3.75%;
  padding: 5% 3.75%;
  height: 100%;
  overflow-y: auto;

  .chooseQuiz {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.25%;
    width: 33.333%;
    background: #F4F6F8;
    border: 4px solid #C4CDD5;
    border-radius: 8px;
    position: relative;
    padding: 6.25% 5%;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    color: #7A310A;
    cursor: pointer;

    &.choose {
      background-color: #FFF3D6;
      border: 4px solid #FFA235;
    }
  }

  .quizIcon {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 8px;
  }

  @media screen and (max-height: 300px) {
    padding: 1% 1%;
    .chooseQuiz {
      max-height: 183px;
    }
    .quizIcon {
      width: auto;
      max-height: 55px;
    }
  }

  @media screen and (min-width: 1440px) {
    .chooseQuiz {
      font-size: 1.667vw;
    }
  }
}

.chooseIcon {
  display: none;
  position: absolute;
  top: 0.167em;
  right: 0.167em;
  width: 1.333em;

  &.choose {
    display: inline;
  }
}

.noAccess {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 49px 0px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  
  .noAccessText {
    color: white;
    font-weight: 400;
    font-size: 20px;
    margin: 12px 0px;
    img {
      height: 25px;
      vertical-align: text-bottom;
      margin-right: 12px;
    }
  }

  a {
    color: white;
  }

  &.show {
    display: block;
  }
}

.empty {
  margin: 0 auto;
  color: #315D83;
  width: 500px;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;

  @media screen and (min-width: 1440px) {
    font-size: 1.389vw;
  }
}

.loadingContent {
  margin: 0 auto;
  text-align: center;
  color: #315D83;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  min-height: 212px;

  p {
    margin: 0.667em 0 0;
  }

  @media screen and (min-width: 1440px) {
    font-size: 1.667vw;
  }
}

.endCloseContent {
  padding: 3.75% 5%;

  .confirmDialog {
    font-size: 28px;
    font-weight: 500;
    color: #637381;
    text-align: center;
    
    span {
      color: #D32613;
    }
  }

  @media screen and (min-width: 1440px) {
    .confirmDialog {
      font-size: 1.667vw;
    }
  }
}

.snackbarWrap {
  position: relative;
  z-index: 100001;
}

.snackbarIcon {
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    border-radius: 12px;
    padding: 8px;
    background-color: rgba(17, 196, 115, 0.16);
  }
}

.disabled {
  cursor: not-allowed;
  button {
    pointer-events: none;
  }
}