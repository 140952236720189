.mathToolsContainer {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  z-index: 10001;

  .iframe {
    width: 100%;
    height: 100%;
  }

  .closeLeftButton {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .closeRightButton {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}