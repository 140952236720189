.button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
  margin-right: 5px;
  font-size: 18px;
  border-radius: 50%;
  background-color: #79cdbf;
  color: #fff;
  text-align: center;
  box-shadow: 1px 1px 5px #999;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  &.active {
    background-color: #f49499;
  }
}
