.musicModal {
  display: flex;
  justify-content: center;
  position: absolute;
  right:0;
  left: 0;
  bottom:0;
  transform: translateX(-50%) translateY(-100%);
  z-index: 100;
}
