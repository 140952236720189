
.v rt { 	font-family: BopomofoRuby; }

.v {
 writing-mode: vertical-rl;
 text-orientation: upright;
}

rt{
  font-size: 2vmin;
  ruby-align: center;
  text-align: center;
  text-orientation: upright;
  letter-spacing: 0;
}
