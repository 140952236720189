@import '../../../style/media';

.audioControlsButton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 60px;
  min-height: 60px;
  font-size: 18px;
  font-weight: bold;
  @include phone{
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }
  .audioButton {
    width: 100%;
    height: 100%;
    svg{
      width: 80%;
      height: 80%;
      path {
        fill: #fff;
      }
    }
  }
}
