.wordContentList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;

  .wordList {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    height: max-content;
  }

  .word {
    width: auto;
    height: auto;
    max-height: calc(6rem + 10px);
    font-size: 1.5rem;
    writing-mode: vertical-rl;
    background-color: #85e7e2;
    border-radius: 5px;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    white-space: nowrap;

    &.active {
      background-color: #eb95b7;
    }

    &.zhuyin {
      display: flex;
      flex-wrap: wrap;
      min-width: 2.5rem;
      max-height: calc(15rem + 10px);
      padding: 5px 10px;

      .bopomofo {
        margin-bottom: 5px;
      }

      ruby {
        margin-bottom: 0.3rem;
      }
    }
  }

  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    background-color: #64c8d1;
    font-size: 3vmin;
    cursor: pointer;

    &.disabled {
      background-color: #ccc;
    }
  }
}