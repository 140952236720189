.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  background-color: #79cdbf;
  user-select: none;
  cursor: pointer;
  &.active {
    background-color: #f49499;
  }
  &.disabled{
    background-color: #999;
  }
}
