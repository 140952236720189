.snackbarContainer {
  position: relative;
  z-index: 100001;
}

.snackbarIcon {
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    border-radius: 12px;
    padding: 8px;
  }
  &.success {
    img {
      background-color: rgba(17, 196, 115, 0.16);
    }
  }
}
