.painterMain {
  position: absolute;
  width: 40vmin;
  min-width: 200px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  bottom: 1%;

  & > * {
    padding-left: 0 !important;
  }

  .closeBtn {
    color: #fff;
    position: absolute;
    top: 5px;
    right: 10px;
    width: auto;
    cursor: pointer;
  }

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }
}

.buttonGroup {
  display: flex;
  align-items: center;
}

.division_line {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 10px 0;
}
