.paiquizMask {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: absolute;
  z-index: 99999;
}

.boxModal {
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  background-color: #D9F1F8;
  box-shadow: 2px 2px 10px #333;
  border-radius: 20px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000;
  transform: translate(-50%, -50%) !important ;
  width: 45lvw;
  min-width: 640px;
  @media(max-height: 400px) {
    height: 95%;
  }

  .content {
    flex-grow: 1;
    // overflow: hidden;
    overflow-y: auto;
  }

  .bottom {
    display: flex;
    justify-content: center;
    gap: 5%;
    padding: 1.25% 1.88% 1.88%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 2.5% 3.75% 3.75%;
      appearance: none;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;
      border-radius: 12px;
      white-space: nowrap;
      cursor: pointer;

      &.confirmClose {

      }
      &.goBack {
        color: #454F5B;
        background: #C4CDD5;
        border-bottom: 5px solid #637381;
      }
    }

    @media screen and (max-height: 560px) {
      padding: 10px 16px;
      button {
        font-size: 18px;
        line-height: 1.556
      }
    }

    @media screen and (min-width: 1440px) {
      span,
      button {
        font-size: 1.667vw;
      }
    }
  }

  .controlHead {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2.5%;
    width: 100%;
    background-color: #315D83;
    cursor: move;
    h1, .title {
      color: white;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.5;
      margin: 0 auto;
    }

    .closeButton {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      box-shadow: 1px 1px 5px #333;
      background-color: #fff;
      color: #ec6464;
      cursor: pointer;

      svg {
        width: 60%;
        height: auto;
      }
    }

    @media screen and (max-height: 560px) {
      padding: 1.25%;
      h1, .title {
        font-size: 18px;
        line-height: 1.556;
      }
      .closeButton {
        width: 20px;
        height: 20px;
      }
    }

    @media screen and (min-width: 1440px) {
      span {
        font-size: 2.222vw;
      }
      .closeButton {
        width: 1.875vw;
        height: 1.875vw;
      }
    }
  }
  .oneLinkTitle {
    font-size: 32px;
    font-weight: 500;
    color: white;
    width: 510px;
    margin-top: 0;
    margin-bottom: 12px;
    text-align: center;
    img {
      height: 33px;
      vertical-align: middle;
      margin-right: 11px;
    }
  }
  .oneLinkDialog {
    color: white;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
  .oneLinkLink {
    display: block;
    color: #EC7963;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    margin: 0 auto 16px;
    &.forElectron {
      width: 120px;
      color: #EC7963;
      background: transparent;
    }
  }
}