// font-size
$font-large: 10vmin; // 大字型
$font-medium: 8vmin; // 中字型
$font-small: 6vmin; // 小字型

// img-size
$img-large: 50vmin;
$img-medium: 30vmin;
$img-small: 20vmin;



// 直書型
.word_section {
  overflow: auto;
  &.phonetic {
    &.medium {
      font-size: 8vmin !important;
    }
  }

  &.large {
    // font-size: $font-large !important;
    // .imgSize{
    //     width: $img-large;

    //     img{
    //         width: 100%;
    //     }
    // }

    // .article {
    //   .square {
    //     font-size: 5vmin;
    //     margin-right: 9.5vmin;
    //   }

    //   .ch {
    //     margin-right: 8vmin;
    //   }

    //   .circleWord {
    //     width: 6vmin;
    //     height: 6vmin;
    //     font-size: 6vmin;
    //   }

    //   .paragraph {
    //     .content {
    //       .img {
    //         width: 10vmin;
    //       }
    //     }
    //   }

    //   .wordAns,
    //   .indent {
    //     .img {
    //       width: 5.8vmin;
    //       right: 3vmin;
    //     }
    //   }
    // }

    // &.PHONETIC.CHINESE.ELEMENTARY {
    //   .article {
    //     .paragraph {
    //       .begin {
    //         margin-right: 0;
    //       }
    //     }
    //   }
    // }

    // &.CHINESE.SECONDARY {
    //   .article {
    //     .paragraph {
    //       .begin {
    //         margin-right: 6.5vmin;
    //       }

    //       .content {
    //         .img {
    //           img {
    //             margin-left: 4vmin;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &.medium {
    // font-size: $font-medium !important;
    // .imgSize{
    //     width: $img-medium;

    //     img{
    //         width: 100%;
    //     }
    // }

    // .article {

    //   .circle {
    //     width: 70px;
    //     height: 70px;
    //     margin-right: 28px;
    //   }

    //   .square {
    //     font-size: 4vmin;
    //     margin-right: 6.5vmin;
    //   }

    //   .ch {
    //     margin-right: 5vmin;
    //   }

    //   .circleWord {
    //     width: 5vmin;
    //     height: 5vmin;
    //     font-size: 4vmin;
    //   }

    //   .paragraph {
    //     .content {
    //       .img {
    //         width: 7vmin;
    //       }
    //     }
    //   }

    //   .wordAns,
    //   .indent {
    //     .img {
    //       width: 3.5vmin;
    //       right: 2vmin;
    //     }
    //   }
    // }

    // &.PHONETIC.CHINESE.ELEMENTARY {
    //   .article {
    //     .paragraph {
    //       .begin {
    //         margin-right: 0;
    //       }
    //     }
    //   }
    // }

    // &.CHINESE.SECONDARY {
    //   .article {
    //     .paragraph {
    //       .begin {
    //         margin-right: 4.5vmin;
    //       }

    //       .content {
    //         .img {
    //           img {
    //             margin-left: 3vmin;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &.small {
    // font-size: $font-small !important;
    // .imgSize{
    //     width: $img-small;

    //     img{
    //         width: 100%;
    //     }
    // }

    // .article {
    //   &.chineseOnly {
    //     .circle {
    //       width: 30px;
    //       height: 30px;
    //       transform: translateX(12.5px);
    //     }
    //   }
    //   .circle {
    //     width: 50px;
    //     height: 50px;
    //     margin-right: 16px;
    //   }

    //   .tc {
    //     margin-right: 0.8em;
    //   }

    //   .tc ~ sign {
    //     margin-right: 0.4em;
    //   }

    //   .square {
    //     font-size: 2vmin;
    //     margin-right: 3.5vmin;
    //   }

    //   .ch {
    //     margin-right: 2.5vmin;
    //   }

    //   .circleWord {
    //     width: 3vmin;
    //     height: 3vmin;
    //     font-size: 3vmin;
    //   }

    //   .paragraph {
    //     .content {
    //       .img {
    //         width: 3vmin;
    //       }
    //     }
    //   }

    //   .wordAns,
    //   .indent {
    //     .img {
    //       width: 1.8vmin;
    //       right: 1vmin;
    //     }
    //   }

    //   sign {
    //     right: 0.2em;
    //   }
    // }

    // &.PHONETIC.CHINESE.ELEMENTARY {
    //   .article {
    //     .paragraph {
    //       .begin {
    //         margin-right: 1vmin;
    //         display: inline-block;
    //       }
    //     }
    //   }
    // }

    // &.CHINESE.SECONDARY {
    //   .article {
    //     .paragraph {
    //       .begin {
    //         margin-right: 2.5vmin;
    //         display: inline-block;
    //       }

    //       .content {
    //         .img {
    //           img {
    //             margin-left: 2vmin;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // 轉檔出來多的p標籤
  & ~ p {
    display: none;
  }
}

.large .word_section {
  font-size: $font-large !important;
  .indent {
    text-indent: 2.2 * $font-large;
  }
  .article {
    .square {
      font-size: 5vmin;
      margin-right: 9.5vmin;
    }

    .ch {
      margin-right: 8vmin;
    }

    .circleWord {
      width: 6vmin;
      height: 6vmin;
      font-size: 6vmin;
    }

    .paragraph {
      .content {
        .img {
          width: 10vmin;
        }
      }
    }

    .wordAns,
    .indent {
      .img {
        width: 5.8vmin;
        right: 3vmin;
      }
    }
  }

  &.PHONETIC.CHINESE.ELEMENTARY {
    .article {
      .paragraph {
        .begin {
          margin-right: 0;
          display: inline-block;
          width:auto !important;
        }
      }
    }
  }

  &.CHINESE.SECONDARY {
    .article {
      .paragraph {
        .begin {
          margin-right: 6.5vmin;
          display: inline-block;
          width:auto !important;
        }

        .content {
          .img {
            img {
              margin-left: 4vmin;
            }
          }
        }
      }
    }
  }
}

.medium .word_section {
  font-size: $font-medium !important;
  .indent {
    text-indent: 2.2 * $font-medium;
  }
  .article {

    .circle {
      width: 70px;
      height: 70px;
      margin-right: 28px;
    }

    .square {
      font-size: 4vmin;
      margin-right: 6.5vmin;
    }

    .ch {
      margin-right: 5vmin;
    }

    .circleWord {
      width: 5vmin;
      height: 5vmin;
      font-size: 4vmin;
    }

    .paragraph {
      .content {
        .img {
          width: 7vmin;
        }
      }
    }

    .wordAns,
    .indent {
      .img {
        width: 3.5vmin;
        right: 2vmin;
      }
    }
  }

  &.PHONETIC.CHINESE.ELEMENTARY {
    .article {
      .paragraph {
        .begin {
          margin-right: 0;
          display: inline-block;
          width:auto !important;
        }
      }
    }
  }

  &.CHINESE.SECONDARY {
    .article {
      .paragraph {
        .begin {
          margin-right: 4.5vmin;
          display: inline-block;
          width:auto !important;
        }

        .content {
          .img {
            img {
              margin-left: 3vmin;
            }
          }
        }
      }
    }
  }
}

.small .word_section {
  font-size: $font-small !important;
  .indent {
    text-indent: 2.1 * $font-small;
  }
  .article {
    &.chineseOnly {
      .circle {
        width: 30px;
        height: 30px;
        transform: translateX(12.5px);
      }
    }
    .circle {
      width: 50px;
      height: 50px;
      margin-right: 16px;
    }

    .tc {
      margin-right: 0.8em;
    }

    .tc ~ sign {
      margin-right: 0.4em;
    }

    .square {
      font-size: 2vmin;
      margin-right: 3.5vmin;
    }

    .ch {
      margin-right: 2.5vmin;
    }

    .circleWord {
      width: 3vmin;
      height: 3vmin;
      font-size: 3vmin;
    }

    .paragraph {
      .content {
        .img {
          width: 3vmin;
        }
      }
    }

    .wordAns,
    .indent {
      .img {
        width: 1.8vmin;
        right: 1vmin;
      }
    }

    sign {
      right: 0.2em;
    }
  }

  &.PHONETIC.CHINESE.ELEMENTARY {
    .article {
      .paragraph {
        .begin {
          margin-right: 1vmin;
          display: inline-block;
          width:auto !important;
        }
      }
    }
  }

  &.CHINESE.SECONDARY {
    .article {
      .paragraph {
        .begin {
          margin-right: 2.5vmin;
          display: inline-block;
          width:auto !important;
        }

        .content {
          .img {
            img {
              margin-left: 2vmin;
            }
          }
        }
      }
    }
  }
}

// 題目型系列
.topic {
  overflow: auto;
  &.large {
    font-size: $font-large;
    // .imgSize{
    //     width: $img-large;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  &.medium {
    font-size: $font-medium;
    // .imgSize{
    //     width: $img-medium;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  &.small {
    font-size: $font-small;
    // .imgSize{
    //     width: $img-small;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  // 轉檔出來多的p標籤
  & ~ p {
    display: none;
  }
}

// 純文字型
.plain_text {
  overflow: auto;
  &.large {
    font-size: $font-large;

    // .imgSize{
    //     width: $img-large;

    //     img{
    //         width: 100%;
    //     }
    // }

    .title {
      font-size: 13vmin;
    }
  }

  &.medium {
    font-size: $font-medium;

    // .imgSize{
    //     width: $img-medium;

    //     img{
    //         width: 100%;
    //     }
    // }

    .title {
      font-size: 10vmin;
    }
  }

  &.small {
    font-size: $font-small;

    // .imgSize{
    //     width: $img-small;

    //     img{
    //         width: 100%;
    //     }
    // }

    .title {
      font-size: 7vmin;
    }
  }

  // 轉檔出來多的p標籤
  & ~ p {
    display: none;
  }
}

// 填充型
.fill {
  overflow: auto;
  &.large {
    font-size: $font-large;

    // .imgSize{
    //     width: $img-large;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  &.medium {
    font-size: $font-medium;

    // .imgSize{
    //     width: $img-medium;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  &.small {
    font-size: $font-small;

    // .imgSize{
    //     width: $img-small;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  // 轉檔出來多的p標籤
  & ~ p {
    display: none;
  }
}

// 圖片型
.imageSet {
  overflow: auto;
  &.large {
    font-size: $font-large;

    // .imgSize{
    //     width: $img-large;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  &.medium {
    font-size: $font-medium;

    // .imgSize{
    //     width: $img-medium;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  &.small {
    font-size: $font-small;

    // .imgSize{
    //     width: $img-small;

    //     img{
    //         width: 100%;
    //     }
    // }
  }

  // 轉檔出來多的p標籤
  & ~ p {
    display: none;
  }
}

// 簡答題
.simple {
  // overflow: hidden;
  &.large {
    font-size: $font-large;

    // .imgSize{
    //     width: $img-large;

    //     img{
    //         width: 100%;
    //     }
    // }

    .content {
      .ans_box {
        .img {
          width: 20vmin;
        }
      }
    }
  }

  &.medium {
    font-size: $font-medium;

    // .imgSize{
    //     width: $img-medium;

    //     img{
    //         width: 100%;
    //     }
    // }

    .content {
      .ans_box {
        .img {
          width: 15vmin;
        }
      }
    }
  }

  &.small {
    font-size: $font-small;

    // .imgSize{
    //     width: $img-small;

    //     img{
    //         width: 100%;
    //     }
    // }

    .content {
      .ans_box {
        .img {
          width: 10vmin;
        }
      }
    }
  }
}

// 問答結果顯示
#result {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  color: steelblue;
  display: flex;
  align-items: center;
  justify-content: center;

  .good {
    font-size: 100px;
    color: #0f0;
  }

  .bad {
    font-size: 100px;
    color: #f00;
  }
}

// 轉檔多轉出來的標籤
.MsoNormal {
  display: none;
}

// 轉檔出來的table下的p標籤顯示
.MsoNormalTable p {
  display: block;
}

// 針對注音標籤樣式調整
hruby {
  line-height: 1.8 !important;
}

.word_section.small.PONETIC.CHINESE.ELEMENTARY .article.chineseOnly .paragraph .begin {
  margin-right: 0;
  display: inline-block;
}
