.wordRadicalContent{
  display: flex;
  text-align: left;
  height: 100%;

  .content{
    backface-visibility: hidden;
  }

  & > div{
    height: 100%;
  }
}
