.boxModal {
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  background-color: #51464c;
  box-shadow: 2px 2px 10px #333;
  border-radius: 20px;
  padding: 10px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000;
  .content {
    display: inline-block;
    position: relative;
    resize: both;
    overflow: hidden;
    width: 40vw;
    height: 30vw;
    padding-bottom: 36px;
    &::-webkit-resizer {
      background-color: transparent;
    }
  }

  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .controlHead{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    cursor: move;
  
    .closeButton {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 12px;
      margin-right: 30px;
      box-shadow: 1px 1px 5px #333;
      background-color: #fff;
      font-size: 2.5vmin;
      color: #ec6464;
      cursor: pointer;

      span {
        display: inline-block;
        position: absolute;
        right: 100%;
        width: 50px;
        color: #fff;
        font-size: 16px;
      }
    }
  }


  .expandIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: transparent;
    transform: rotate(90deg);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}