.translationWrapper{
  position: relative;
  overflow: hidden;
  .pageControl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    z-index: 100;
    .pageControlDot {
      position: relative;
      width: 15px;
      height: 15px;
      background-color: #65c8d0;
      border-radius: 50%;
      cursor: pointer;
      + .pageControlDot {
        margin-top: 5px;
      }

      &.active:after {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        width: 50%;
        height: 50%;
        border-radius: 50%;
      }
    }
  }
  .controls {
    position: absolute;
    bottom: 0px;
    right: 35px;
    display: flex;
    justify-content: flex-end;
    // width: 100%;
    height: auto !important;
    background-color: #fff;
    padding: 10px;
    z-index: 100;
  }
  .translation {
    height: 100%;
    overflow-y: auto;
    &.large {
      font-size: 10vmin;
    }
    &.medium {
      font-size: 8vmin;
    }
    &.small {
      font-size: 6vmin;
    }

    .contents {
      position: relative;
      padding-right: 50px;
      padding-bottom: 90px;
    }
    .contentList {
      margin-bottom: 10px;
      .listItem {
        display: flex;
        .sentence {
          padding: 10px;
          opacity: 0;
          cursor: pointer;
          &.active {
            opacity: 1;
          }
        }
        &.en {
          .sentence {
            padding: 15px 10px;
          }
        }
        &.ch {
          display: flex;
          align-items: stretch;
          border-bottom: 2px solid #65c8d0;
          .sentence {
            position: relative;
            width: 100%;
            padding: 10px;
            color: rgb(16, 113, 188);
          }
        }
        .icon {
          position: relative;
          margin-top:15px;
          height: 100%;
        }
      }
    }
  }
}
