.timePicker{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  max-width: 120px;
  border-radius: 5px;
  .input {
    margin: 0;
    input{
      border: none;
      width: 100%;
      text-align: center;
    }
  }
}
