.immediateContent {
  display: flex;
  align-items: center;
  padding: calc((24vmin / 900) * 100);
  gap: calc((24vmin / 900) * 100);
}

.qrCode {
  box-sizing: border-box;
  flex-basis: 50%;
  flex-grow: 1;
  background: white;
  padding: calc((12vmin / 900) * 100);
  border: 1px solid #d5d7de;
  border-radius: calc((8vmin / 900) * 100);
  height: auto; /* 保持宽高比例 */
  svg {
    width: 100%;
    height: auto;
  }
}

.qrTextBlock {
  flex-basis: 50%;
  flex-grow: 1;
  font-family: "Noto Sans TC";
}

.qrTitle {
  margin-bottom: calc((16vmin / 900) * 100);
  color: #315d83;
  font-size: calc((32vmin / 900) * 100);
  font-weight: 700;
  line-height: 1.5;
}

.qrHeading {
  font-size: calc((24vmin / 900) * 100);
  font-weight: 700;
  line-height: 1.5;
}

.qrLinkArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc((8vmin / 900) * 100);
  padding: calc((8vmin / 900) * 100);
  margin: calc((4vmin / 900) * 100) 0 calc((16vmin / 900) * 100);
  font-size: calc((13vmin / 900) * 100);
  color: #315d83;
  border: 2px solid #88bcd9;
  border-radius: calc((8vmin / 900) * 100);
  background-color: #f4f6f8;
}

.linkText {
  flex-grow: 1;
  color: #212b36;
  font-size: calc((16vmin / 900) * 100);
  font-weight: 500;
  line-height: 1.5;
  width: calc((161vmin / 900) * 100);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.qrText {
  color: #637381;
  font-size: calc((20vmin / 900) * 100);
  font-weight: 500;
  line-height: 1.5;
  padding-top: calc((16vmin / 900) * 100);
  border-top: 1px dashed #919eab;
}

.linkButton {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: calc((8vmin / 900) * 100);
  width: auto;
  height: initial;
  padding: calc((4vmin / 900) * 100) calc((10vmin / 900) * 100);
  font-size: calc((13vmin / 900) * 100);
  font-weight: 700;
  line-height: 1.69;
  color: #315d83;
  background-color: #dfe3e8;
  border: none;
  border-radius: calc((6vmin / 900) * 100);
  cursor: pointer;
}

.linkButtonMobile {
  display: none;
}

@media screen and (max-height: 560px) {
  .immediateContent {
    padding: 12px 32px;
    gap: 24px;
  }
  .qrCode {
    flex-basis: 35%;
    padding: 12px;
    border-radius: 8px;
  }
  .qrTextBlock {
    flex-basis: 65%;
  }
  .qrTitle {
    margin-bottom: 16px;
    font-size: 32px;
  }
  .qrHeading {
    font-size: 24px;
  }
  .qrLinkArea {
    gap: 8px;
    padding: 8px;
    margin: 4px 0 16px;
    font-size: 13px;
    border-radius: 8px;
  }
  .linkText {
    font-size: 16px;
  }
  .qrText {
    font-size: 20px;
    padding-top: 16px;
  }
  .linkButton {
    gap: 8px;
    padding: 4px 10px;
    font-size: 13px;
    border-radius: 6px;
  }
  .linkButtonDesktop {
    display: none;
  }
  .linkButtonMobile {
    display: flex;
  }
}

.emptyContent {
  margin: 0 auto;
  padding: calc((24vmin / 900) * 100);
  width: calc((500vmin / 900) * 100);
  height: calc((185vmin / 900) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #315d83;
  font-size: calc((20vmin / 900) * 100);
  font-weight: 700;
  img {
    width: calc((114vmin / 900) * 100);
    height: calc((95vmin / 900) * 100);
  }
}

.loadingContent {
  margin: 0 auto;
  padding: calc((24vmin / 900) * 100);
  text-align: center;
  color: #315d83;
  font-size: calc((24vmin / 900) * 100);
  font-weight: 700;
  line-height: 1.5;
  p {
    margin: calc((16vmin / 900) * 100) 0 0;
  }
}

@media screen and (max-height: 560px) {
  .emptyContent {
    padding: 12px 32px;
    width: 500px;
    height: 185px;
    font-size: 20px;
    img {
      width: 114px;
      height: 95px;
    }
  }
  .loadingContent {
    padding: 12px 32px;
    font-size: 24px;
    p {
      margin: 16px 0 0;
    }
  }
}

.actionsButton > * {
  display: flex;
  justify-content: center;
  width: calc((200vmin / 900) * 100);
  height: auto;
  padding: calc((10vmin / 900) * 100) calc((16vmin / 900) * 100);
  font-size: calc((24vmin / 900) * 100);
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  border: 0;
  border-radius: calc((12vmin / 900) * 100);
  text-decoration: none;
  cursor: pointer;
}

.primaryButton > * {
  color: #7a310a;
  background: #ffa235;
  border-bottom: 5px solid #b7601a;
}

.secondary > * {
  color: #454f5b;
  background: #d4dde5;
  border-bottom: 5px solid #637381;
}

@media screen and (max-height: 560px) {
  .actionsButton > * {
    width: 200px;
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 12px;
  }
}

// ----------------------- EndClose -----------------------
.endCloseContent {
  padding: calc((24vmin / 900) * 100) calc((32vmin / 900) * 100);
}

.confirmDialog {
  color: #637381;
  text-align: center;
  font-size: calc((28vmin / 900) * 100);
  font-weight: 500;
  span {
    color: #d32613;
  }
}

@media screen and (max-height: 560px) {
  .endCloseContent {
    padding: 12px 32px;
  }
  .confirmDialog {
    font-size: 28px;
  }
}
