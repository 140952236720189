.grid {
  display: flex;
  padding-right: 0 !important;
  overflow: initial !important;

  .zoomIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    backface-visibility: hidden;
  }
}

.radicalInfoBar {
  display: flex;
  justify-content: center;
  max-width: 50%;
  margin: 0 auto;
}
