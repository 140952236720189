.dialogContent{
  min-width: 600px;
  .dialogContentHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialogContentBody{
    min-height: 150px;
  }

  .questionSection {
    position: relative;
    border: 1px dotted #555;
    padding: 10px;
    margin-top: 15px;
    .TFTest{
      display: flex;
      .option{
        flex:1;
        + .option{
          margin-left: 10px;
        }
      }
      .radio{
        .icon{
          padding-left: 10px;
        }
      }
    }
    .deleteIcon{
      position: absolute;
      top: 10px;
      right: 10px;
      transition: .3s;
      opacity: 0;
      z-index: -1;
      cursor: pointer;
    }
    &:hover .deleteIcon{
      transition: .3s;
      opacity: 1;
      z-index: 1;
    }
    .option{
      display: flex;
      align-items: center;
      > div{
        width: 100%;
      }
      .label{
        margin-right: 10px;
      }
      textarea{
        border-radius: 5px;
      }
    }
  }

}


.timePointValue{
  label{
    display: flex;
    align-items: center;
    input{
      margin-left: 15px;
    }
  }
}
