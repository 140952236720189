.tabList{
  display: flex;
  flex-direction: column;
  width: 0;
  height: 100%;
  transition: .3s;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 1004;
  &.showing{
    width: 25%;
    transition: .3s;
  }
  .list{
    height: 100%;
    overflow-y: auto;
  }
  .listItem{
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 10px 15px;
    cursor: pointer;
    .text{
      margin-left: 10px;
      color: #000;
      .time{
        color: #aaa;
        font-size: 16px;
      }
    }
  }
}
