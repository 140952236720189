  .checkBox {
    position: relative;
    padding-left: 24px;
   .input{
    display: none;
    &:checked {
      ~ .check{
        background-color: #cc0000;   
      }
    }
   }
   .check{
     position: absolute;
     top: 50%;
     left: 0;
     transform: translateY(-50%);
     width: 18px;
     height: 18px;
     background-color: #fff;
     border-radius: 5px;
     &:after{
      content: '✓';
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-weight: bold;
     }
   }
   .label{

   }
  }