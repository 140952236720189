.answersButton{
  width: 100%;
  padding: 1vmin 0;
  display: flex;
  justify-content: flex-end;

  .answers{
    width: 40px;
    height: 40px;
    margin: 0 5px;
    color: #fff;
    background-color: #6dd1cf;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
