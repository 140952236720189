.stickyContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &.fullDragRange {
        width: 100%;
        height: 100%;
    }
}

.sticky {
    position: absolute;
    width: 50%;
    background-color: rgb(255, 238, 6);
    padding: 20px;
}

.stickyContent {
    height: 100%;
    overflow-y: 'auto';
    .topTools {
        height: 10%;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 1vmin;
        .closeBtn {
            width: 100px;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            // text-align: center;
            align-items: center;
            padding: 5px;
            border-radius: 8px;
            border: #b5b2b2 solid;
            background-color: #ffffff;
            cursor: pointer;
            img {
                width: 25%;
                height: 100%;
            }
            .closeBtnText {
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .htmlTools {
        background-color: #ffffff;
    }
    .editor {
        background-color: #fff;
        height: 70%;
    }
    .bottomTools {
        width: 100%;
        display: flex;
        div {
            height: 5vh;
            width: 8%;
            cursor: pointer;
            margin: 1%;
            border-radius: 8px;
            border: #b5b2b2 solid;
        }
    }
}
