ruby {
  font-family: cursive;
  // margin: 1rem 0;

  rt {
    font-family: BopomofoRuby;
  }
}

sign {
  margin: 0;
  font-family: cursive;
  display: inline-flex;
}

// 大中小
.small {
  sign {
    // font-size: 6vmin;
  }

  // ruby {
  //   rt {
  //     font-size: 2vmin !important;
  //   }
  // }

  .private_name,
  .flow_wave {
    // font-size: calc(100vh / 18);
  }
}

.medium {
  sign {
    // font-size: 6vmin;
  }

  .private_name,
  .flow_wave {
    // font-size: calc(100vh / 15);
  }
}

.large {
  sign {
    // font-size: 7vmin;
  }

  .private_name,
  .flow_wave {
    // font-size: calc(100vh / 10);
  }
}

.zhuyinLesson {
  line-height: 1.5;
  height: 100%;

  .article {
    height: 100%;

    .lesson {
      // 開段
      &.indent {
        text-indent: 2em;
      }

      /* 私名號 直線 */
      .private_name {
        border-left: .5vmin solid transparent;
        border-image: url(line.png) 30 10 repeat;

        &.red {
          border-image: url(redLine.png) 30 10 repeat;
        }

        &.pink {
          border-image: url(pinkLine.png) 30 10 repeat;
        }

        &.blue {
          border-image: url(blueLine.png) 30 10 repeat;
        }
      }

      /* 私名號 波浪 */
      .flow_wave {
        border-left: 1vmin solid transparent;
        border-image: url(wave.png) 30 30 round;

        &.red {
          border-image: url(redWave.png) 30 30 repeat;
        }

        &.pink {
          border-image: url(pinkWave.png) 30 30 repeat;
        }

        &.blue {
          border-image: url(blueWave.png) 30 30 repeat;
        }
      }
    }
  }
}