.textareaWrapper {
  margin: 15px 0;
  label div {
    padding-bottom: 15px;
  }
  textarea {
    width: 100%;
    padding: 10px;
  }
}
