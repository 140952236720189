
.list-item {
  position: relative;
  width: 219px;
  margin: 5px;
  padding: 15px 15px 40px;
  border: 0.1rem solid #ccc;
  border-radius: 0.2rem;
  background: linear-gradient(90deg, #fff 20%, #F4F4F4 80%);
  transition: all .3s ease;
  // grid-template-rows: repeat(auto-fit, minmax(2, 1fr));
  // grid-template-columns: 1fr;
  // grid-auto-rows: minmax(100, auto);

  .list-img {
    width: 100%;
    height: 225px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .list-title {
    // height: 13.33%;
    line-height: 1.3;
    // padding: 0.7rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // padding-bottom: 0.3rem;
    color: #000;
    display: flex;
    align-items: flex-end;
  }
  
  &:hover {
    transform: scale(1.05);
  }

  .list-tool{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
}

.btn{
  display: inline-block;
  width: auto;
  height: auto;
  padding: 5px 12px;
  border: none;
  border-radius: 4px;
  background-color: #EC7963;
  color: #fff;
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
}

.trash{
  font-size: 24px;
  color: #727171;
  cursor: pointer;
}