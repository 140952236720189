.WordExample {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
  background-color: #ddf3f0;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: calc(100% - 120px);
    height: 100%;

    &.hasBreakAnchor {
      width: 88%;
      @media (-webkit-min-device-pixel-ratio: 1) {
        @media screen and (max-width: 1280px) {
          width: 85%;
        }
      }
    }

    .item {
      display: flex;
      width: 100%;
      height: 100%;

      .audio {
        width: 60px;
        outline: none;
      }

      .image {
        height: 100%;

        img {
          height: 100%;
        }
      }
    }
  }

  .breakAnchor {
    width: 10%;
    margin-left: 15px;
    @media (-webkit-min-device-pixel-ratio: 1) {
      @media screen and (max-width: 1280px) {
        width: 15%;
      }
    }
    .anchor {
      margin: 10px 0;
      background-color: #ff99cb;
      border-radius: 5px;
      text-align: center;
      padding: 1vmin .5vmin;
      box-shadow: 2px 2px 3px #aaa;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 3px #aaa;
      }
    }
  }
}
